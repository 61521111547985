import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';

import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import { Paper, CssBaseline } from '@material-ui/core';
import AOS from 'aos';
import { WhatsupBotton } from './views';

const history = createBrowserHistory();
const store = configureStore();

const App = () => {
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });
// {renderRoutes(routes)}
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <Paper>
        <Router history={history}>
            <ScrollReset />
            <GoogleAnalytics />
            <CookiesNotification />
            <WhatsupBotton></WhatsupBotton>
            {renderRoutes(routes)}
          </Router>
      </Paper>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
