import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import { createTheme, responsiveFontSizes } from '@material-ui/core';

/*const theme = createMuiTheme({
  palette,
  typography,
  overrides
});*/


const theme = responsiveFontSizes(
  createTheme({
    palette,
    layout: {
      contentWidth: 1140,
    },
    typography: {
      fontFamily: 'Lato',
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: 'white',
        },
      },
    },
  }),
);

export default theme;
