import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField,InputLabel } from '@material-ui/core';
import validate, { async } from 'validate.js';
import { useHistory } from 'react-router-dom';
import requestApi from 'utils/request';
import { useState, useEffect } from 'react';
import { Form } from 'views/PagosCodigoQR/components';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import { Country, State, City } from "country-state-city";
import Select from "react-select";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
}));

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  direccion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 8,
    },
  },
};

const PagosContraEntrega = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;

  const { values } = props.location.state;
  console.log("nombre producto----: " + (values))

  var precioTotalProd =parseFloat(0);
  var moneda = JSON.parse(values[0]).moneda;
  var thenum = 0;


  var pedido = [];

values.map((value) =>
  //thenum = value.precioTotal;
//console.log(" precioTotal: "+ JSON.parse(value).precioTotal),
precioTotalProd = precioTotalProd + parseFloat(JSON.parse(value).precioTotal)
//console.log("ShopinCartItem producto: " +  JSON.parse(value).producto)

);

//var i = 0;


values.map((value,index) =>
//if(value.key===1){
pedido[index] = JSON.parse(value),
);

console.log("pedido: " +  JSON.stringify(pedido))

 //const pedido = JSON.parse(values);

  const history = useHistory();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(false);
  const [alertContent, setAlertContent] = useState('Ingrese sus datos');

  const [alertSeverity, setAlertSeverity] = useState('info');


  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleClick= event => {
    event.persist();
    console.log("change click form pedidos")

    console.log("formState touched: " + formState.touched[event])
    console.log("handleChange values cantidad:---" + formState.values.cantidad)
   if (formState.values.cantidad != null ) {
 
      console.log("asignando precio")

    }

  };
  const handleSubmit = async event => {
    event.preventDefault();

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));


    if (formState.isValid) {
      setLoad(true);

    }
  };

  const [sesion, setSesion] = useState('');
  const [sesionB, setSesionB] = useState(false);

  //Si se usa usestate se debe usar useEfect.
  useEffect(() => {
    setSesion(sessionStorage.getItem('email'));
    console.log("sesion: " + sessionStorage.getItem('email'))
    if (sesion === null || sesion === undefined || sesion === "") {
      console.log("sesionB true")
      setSesionB(true)
      setSesion(formState.values.correo)
    }
  }, [sesion]);

  var pedidos ={
id_usuario:sesion,
ciudad:formState.values.ciudad,
correo:formState.values.correo,
descripcion_lugar:"",
direccion_entrega:formState.values.direccion,
estado:formState.values.estado,
estado_pedido:"inicio",
pais:formState.values.pais,
movil:formState.values.movil,
productos:pedido,
medio_pago:"contraEntrega",
total: precioTotalProd
}

  const handleOrder = () => {
    console.log("productos del carro:" + pedidos)
    //#PENDIENTE aca se envia el registro del pedido a la base de datos asignar datos de pedido var pedidos
    //mostrar letrero de pedido:
    //realizado con exito, un asesor verificara el comprobante de pago y se comunciara contigo
    //para agendar la entrega.
    
    //aca se envia el registro del pedido a la base de datos
   requestApi("create", "pedidos", pedidos).then(async function (response) {
    // debugger
     if(response.status == 200 && response.data.result==="OK") {
       Swal.fire({
         title: 'Pedido registrado',
         icon: 'success',
         html: 'En los próximos dos días hábiles un asesor se comunicará contigo para realizar la entrega',
         showCancelButton: true,
         confirmButtonText:
           '<i class="fa fa-whatsapp"></i> Contactar',
         cancelButtonText:
           'Cerrar',  
       }).then(result => {
         if (result.isConfirmed) {
           window.location.replace("https://wa.me/3203500458")
         }
         console.log("then result");
         //history.push("/pedidosUsuario");
       })
       console.log("if-----");
        // history.push("/pedidosUsuarios");
     }else{
       Swal.fire({
         title: 'Pedido No registrado',
         icon: 'success',
         html: 'Su pedido No ha sido registrado con exito, Intente nuevamente o comuniquese al whatsup: '+ response.data.result,
         showCancelButton: true,
         confirmButtonText:
           '<i class="fa fa-whatsapp"></i> Contactar',
         cancelButtonText:
           'Cerrar',
       }).then(result => {
         if (result.isConfirmed) {
           window.location.replace("https://wa.me/3203500458")
         }
        // history.push("/pedidosUsuario");
       })
     }
     }
   )
  
  
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
//#PENDIENTE OK Agregar ciudad pais estado al formulario
 //Countries

const [colombia, setColombia] = useState(true);
const setColombiaValue = () => {
  console.log("setColombiaValue");

  setColombia(!colombia);

};
const [selectedCountry, setSelectedCountry] = useState("CO");
const [selectedState, setSelectedState] = useState("Bogota");
const [selectedCity, setSelectedCity] = useState("Bogota");
useEffect(() => {
  console.log("selectedCountry: " + selectedCountry?.name);
  console.log("selectedCountry: ISO code" + selectedCountry?.isoCode);
  console.log(State?.getStatesOfCountry(selectedCountry?.isoCode));
  console.log("use effect country------------------------")
  //formState.values.pais = selectedCountry?.name;
  formState.values.pais = selectedCountry?.isoCode;
  if (selectedCountry != null) {
    console.log("formstate.country: " + formState.values.pais)
  }
}, [selectedCountry]);

useEffect(() => {
  console.log("selectedState: " + selectedState?.name);
  console.log("selectedState Iso Code: " + selectedState?.isoCode);
  console.log(State?.getStatesOfCountry(selectedState?.isoCode));
  console.log("use effect state------------------------")
  formState.values.estado = selectedState?.name;
  if (selectedCity != null) {
    console.log("formstate.state: " + formState.values.state)
  }
}, [selectedState]);

useEffect(() => {
  formState.values.ciudad = selectedCity?.name;
  console.log("selectedCity: " + selectedCity?.name);
  console.log("selectedCity: iso Code" + selectedCity?.isoCode);
  console.log(State?.getStatesOfCountry(selectedCity?.isoCode));
  console.log("use effect City------------------------")
 
  if (selectedCity != null) {
    console.log("formstate.city: " + formState.values.ciudad)
  }
}, [selectedCity]);


  return (
    <div className={classes.root}>
      <Typography style={{marginBottom:'1em'}} component="h3" variant="h3" color="primary" align='center' >
        PAGA EN EL MOMENTO QUE LLEGUE TU PEDIDO
      </Typography>
      <Typography style={{marginBottom:'1em'}} component="h3" variant="h3" color="primary" align='center'>
        Si no estas conforme puedes devolver tu pedido no importa el motivo.
        Por favor informar cualquier inconformidad.
      </Typography>

      <Typography component="h4" variant="h4" color="primary" align='center'>
        El valor a pagar es: {precioTotalProd} {moneda}
      </Typography>

      <Grid item xs={12}>
      <br></br>
      <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  //href='/pedidosConfirmar'
                  onClick={setColombiaValue}
                  fullWidth
                >
                  Fuera de Bogotá-Colombia ?
                </Button> 
                <br></br>
                <br></br>
            {!colombia ? <Grid item xs={12}>
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Pais *</h2></InputLabel>
                <Select
                  options={Country.getAllCountries()}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                  }}
                  required={true}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Estado *</h2></InputLabel>
                <Select
                  className={classes.select}
                  options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                  }}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Ciudad *</h2></InputLabel>
                <Select
                  options={City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  )}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
              </Grid>: <></>}
              <br></br>
      <InputLabel size="normal" id="cantidad-label"><h2 className={classes.h1} >Direccion *</h2></InputLabel>
                <br></br>
                <TextField
                  placeholder="Direccion"
                  label="Direccion de entrega *"
                  variant="filled"
                  size="medium"
                  name="direccion"
                 // helperText={hasError('cantidad')}
                 fullWidth
                  error={hasError('direccion')}
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="String"
                  value={formState.values.direccion || ''}
                  defaultValue="Avenida siempre viva"
                />
        </Grid>
        <Grid item xs={12}>
      <InputLabel size="normal" id="cantidad-label"><h2 className={classes.h1} >Correo *</h2></InputLabel>
                <br></br>
                <TextField
                  placeholder="Correo"
                  label="Correo electronico *"
                  variant="filled"
                  size="medium"
                  name="correo"
                 // helperText={hasError('cantidad')}
                 fullWidth
                  error={hasError('correo')}
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="String"
                  value={formState.values.correo|| ''}
                  defaultValue="ejemplo@gmail.com"
                />
        </Grid>
        <Grid item xs={12}>
      <InputLabel size="normal" id="cantidad-label"><h2 className={classes.h1} >Numero de contacto *</h2></InputLabel>
                <br></br>
                <TextField
                  placeholder="movil"
                  label="movil *"
                  variant="filled"
                  size="medium"
                  name="movil"
                 // helperText={hasError('cantidad')}
                 fullWidth
                  error={hasError('movil')}
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="String"
                  value={formState.values.movil || ''}
                  defaultValue="3203547896"
                />
        </Grid>
        
      <br></br>
          <Typography component="h4" variant="h4" color="primary" align='center'>
        Una vez realices el pago por favor da click al boton de abajo:
              </Typography>
          <br></br>

          <Button
            size="large"
            variant="contained"
            onClick={handleOrder}
            color="success"
            fullWidth
            disabled={!formState.isValid}
          >
            Realizar pedido.
          </Button>

      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Selpii SAS. Colombia
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Calidad Garantizada
        </Typography>
       
      </Box>

    </div>
  );
};

export default PagosContraEntrega;
