import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField,InputLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import validate, { async } from 'validate.js';
import requestApi from 'utils/request';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //height: '100%'
    height: '40em'
  },
  formContainer: {
    height: '20%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom:'5em',
    //alignItems: 'center',
    //justifyContent: 'center',
   // minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    minHeight:'10em',
    margin: `1em auto`,
  },
}));

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  direccion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 120,
    },
  },

};

const PagosPayUReturn = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;

  const { values } = props.location.state;
  console.log("productos payU----: " + values)

  var precioTotalProd =parseFloat(0);
  var moneda = JSON.parse(values[0]).moneda;
  var signatureS = "";
  
  var thenum = 0;
values.map((value) =>
  //thenum = value.precioTotal;
//console.log(" precioTotal: "+ JSON.parse(value).precioTotal),
precioTotalProd = precioTotalProd + parseFloat(JSON.parse(value).precioTotal)
//console.log("ShopinCartItem producto: " +  JSON.parse(value).producto)

);



console.log("precio total payu----: " + precioTotalProd)

console.log("moneda total payu----: " + moneda)


const precioTotalProdS = ''+precioTotalProd;

var precioTotalProdSS = precioTotalProdS;

console.log("precio total payu string----: " + precioTotalProdS)
  const history = useHistory();

  const URL = process.env.REACT_APP_API_URL_PAYU;
  const handleSubmit = async event => {
  };

  const [formState, setFormState] = useState({
    isValid: false,
    //values: { correo: sessionStorage.email },
    values: {},
    touched: {},
    errors: {},
  });


  const [signature, setSignature] = useState("");
  
  var referenceCodev = "Test";
   const [referenceCode, setReferenceCode] = useState("");

//No asigna el valor en la respuesta
  const getReferenceCode = () =>{

    console.log("getReferenceCode------------------------------")

    formState.values.id = "2"
 
      requestApi("getScanReferenceCode", "utils", formState.values).then(async function (response) {
        if (response.status == 200) {
          console.log("status200 getReferenceCode");
          console.log("result " + JSON.stringify(response.data.result[0].id_reference_code));
          console.log(response)
          //setPrecioTotal(response.data.result.precio)
          setReferenceCode(JSON.stringify(response.data.result[0].id_reference_code))
          referenceCodev = JSON.stringify(response.data.result[0].id_reference_code);
          console.log("id_reference_code: "+ referenceCode);
          console.log("id_reference_codev: "+ referenceCodev);
          return referenceCodev
        }
        return referenceCodev

      })
      return referenceCode

  };
  //En este metodo arroja en el body la firma y en el campo result al referenceCode
  //Payu Funcionando OK
  const getSignature = async event => {
    console.log("getSignature------------------------------")

  
    console.log("pedido precio total: " + precioTotalProdS+moneda)
    //if (formState.values.cantidad != null && formState.values.ciudad != null && formState.values.correo != null) {
      console.log("referenceCode: " + referenceCodev)
      formState.values.referenceCode = precioTotalProdS+moneda;
      formState.values.amount = precioTotalProdS;
      formState.values.currency = moneda;

    if (precioTotalProdS != null) {
    
      console.log("get siganture")
      requestApi("getSignPayU", "n/a", formState.values).then(function (response) {
        if (response.status == 200) {
          console.log("status200 getSignature");
          console.log("signature: ------- " + response.data.body);
          console.log(response)
          //setPrecioTotal(response.data.result.precio)
          formState.values.referenceCode = response.data.result;
          console.log("referenceCode: ------" + formState.values.referenceCode);
          setSignature(response.data.body)
        }

      })

    }
  };


 


  const [sesionB, setSesionB] = useState(false);
  const [errorB, setErrorB] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    formState.values.merchanId="00000"
  }, [formState.values]);

  console.log("form values merchanID: "+formState.values.merchanId)


  const handleChange = event => {
    event.persist();
    console.log("change form pedidos")

    if (formState.values.cantidad != null ) {
 
      console.log("asignando precio")
      handlePrice();
    }
    
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    //formState.values.producto = product.nombre;
    //formState.values.cantidad = cantidad;
    console.log("formState touched: " + formState.touched[event])
    console.log("handleChange values cantidad:---" + formState.values.cantidad)



  };

  const hasError = field => {
    console.log("formState touched error: " + field)
    console.log("result errors form: "+formState.errors[field]);
   let result =  formState.touched[field] && formState.errors[field] ? true : false;
   console.log("result errors form: "+result);
    //setErrorB(formState.touched[field] && formState.errors[field] ? true : false);
    //if(field==="cantidad"){
    // console.log("formState touched cantidad")
    //handlePrice();
    // }
  }

  
  const handleClick= event => {
    event.persist();
    console.log("change click form pedidos")

    console.log("formState touched: " + formState.touched[event])
    console.log("handleChange values cantidad:---" + formState.values.cantidad)
   if (formState.values.cantidad != null ) {
 
      console.log("asignando precio")

    }

  };

 //Countries

 const [colombia, setColombia] = useState(true);

 const setColombiaValue = () => {
   console.log("setColombiaValue");

   setColombia(!colombia);

 };

 const [selectedCountry, setSelectedCountry] = useState("CO");
 const [selectedState, setSelectedState] = useState("Bogota");
 const [selectedCity, setSelectedCity] = useState("Bogota");
 useEffect(() => {
   console.log("selectedCountry: " + selectedCountry?.name);
   console.log("selectedCountry: ISO code" + selectedCountry?.isoCode);
   console.log(State?.getStatesOfCountry(selectedCountry?.isoCode));
   console.log("use effect country------------------------")
   //formState.values.pais = selectedCountry?.name;
   formState.values.pais = selectedCountry?.isoCode;
   if (selectedCountry != null) {
     console.log("formstate.country: " + formState.values.pais)
   }
 }, [selectedCountry]);

 useEffect(() => {
   console.log("selectedState: " + selectedState?.name);
   console.log("selectedState Iso Code: " + selectedState?.isoCode);
   console.log(State?.getStatesOfCountry(selectedState?.isoCode));
   console.log("use effect state------------------------")
   formState.values.estado = selectedState?.name;
   if (selectedCity != null) {
     console.log("formstate.state: " + formState.values.state)
   }
 }, [selectedState]);

 useEffect(() => {
   console.log("selectedCity: " + selectedCity?.name);
   console.log("selectedCity: iso Code" + selectedCity?.isoCode);
   console.log(State?.getStatesOfCountry(selectedCity?.isoCode));
   console.log("use effect City------------------------")
   formState.values.ciudad = selectedCity?.name;
   if (selectedCity != null) {
     console.log("formstate.city: " + formState.values.ciudad)
   }
 }, [selectedCity]);

 let sesionS = "";

 //#PENDIENTE:OK Ejecutar primero getReferenceCode y luego GetSIgnature
 useEffect(() => {
 
  sesionS = sessionStorage.getItem('email');
  console.log("sesionS:---" + sesionS)
  
 
  getSignature();


  if (sesionS !== null) {
    setSesionB(true)
    formState.values.correo = sesionS;
    
    console.log("formState.values.correo:---" + formState.values.correo)
  }
}, [sesionS]);


     //Verificacion de correo dn formulario:


//#NOTA: PENDIENTE: Se podria dar el referenceCode como el numero de pedido(id de tabla pedidos consecutivo), primero confirmar el pago 
//y al final guardar en base de datos el registro de pedido. Esto se haria en la pagina de respuesta.
//La descripcion puede ser Selpii pedido + referenceCode.
//#PENDIENTE OK Pedir al usuario los datos de la direccion de entrega envio para asignarlos a la peticion y luego si enviar.
//#PENDIENTE OK Crear servicio para generar  campo signature OK pendiente integrar OK
//#PENDIENTE crear servicio en lambda para recibir get de respuesta de transaccion
//https://stackoverflow.com/questions/35352638/how-to-get-parameter-value-from-query-string
//#PENDIENTE crear ruta y pagina para recibir pagina de respuesta
//https://www.selpii.net/respuestaPayU?merchantId=508029&merchant_name=Test+PayU&merchant_address=Av+123+Calle+12&telephone=7512354&merchant_url=http%3A%2F%2Fpruebaslapv.xtrweb.com&transactionState=6&lapTransactionState=DECLINED&message=DECLINED&referenceCode=TestPayUselpii04&reference_pol=2151541953&transactionId=35534028-9ce0-4d69-a548-4f1bb06f29fc&description=TestPAYUselpii04&trazabilityCode=&cus=&orderLanguage=es&extra1=&extra2=&extra3=&polTransactionState=6&signature=8b82581a290a96b812c952797d002897&polResponseCode=9999&lapResponseCode=INTERNAL_PAYMENT_PROVIDER_ERROR&risk=&polPaymentMethod=758&lapPaymentMethod=NEQUI&polPaymentMethodType=19&lapPaymentMethodType=PROCCESSOR_INTEGRATED_WALLET&installmentsNumber=1&TX_VALUE=2000.00&TX_TAX=.00&currency=COP&lng=es&pseCycle=&buyerEmail=test%40test.com&pseBank=&pseReference1=&pseReference2=&pseReference3=&authorizationCode=&TX_ADMINISTRATIVE_FEE=.00&TX_TAX_ADMINISTRATIVE_FEE=.00&TX_TAX_ADMINISTRATIVE_FEE_RETURN_BASE=.00&processingDate=2024-03-14
 
//PENDIENTE: Generar reference code unico, puede ser generarlo con un consecutivo de base de datos de tabla otros

return (
    <div className={classes.root}>
       <div className={classes.formContainer}>
     <Grid item xs={12}>
     <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  //href='/pedidosConfirmar'
                  onClick={setColombiaValue}
                  fullWidth
                >
                  Fuera de Bogotá-Colombia ?
                </Button> 
                <br></br>
                <br></br>
            {!colombia ? <Grid item xs={12}>
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Pais *</h2></InputLabel>
                <Select
                  options={Country.getAllCountries()}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                  }}
                  required={true}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Estado *</h2></InputLabel>
                <Select
                  className={classes.select}
                  options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                  }}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Ciudad *</h2></InputLabel>
                <Select
                  options={City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  )}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
              </Grid>: <></>}
                <InputLabel size="normal" id="cantidad-label"><h2 className={classes.h1} >Direccion *</h2></InputLabel>
                <br></br>
                <TextField
                  placeholder="Direccion"
                  label="Direccion de entrega *"
                  variant="filled"
                  size="medium"
                  name="direccion"
                 // helperText={hasError('cantidad')}
                 fullWidth
                  error={hasError('direccion')}
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="String"
                  value={formState.values.direccion || ''}
                  defaultValue="Avenida siempre viva"
                />
              </Grid>
              {sesionB?
              <Grid item xs={12}>
              <InputLabel size="normal" id="cantidad-label"><h2 className={classes.h1} >Correo *</h2></InputLabel>
                <br></br>
                <TextField
                  placeholder="Correo"
                  label="Correo electronico *"
                  variant="filled"
                  size="medium"
                  name="correo"
                 // helperText={hasError('cantidad')}
                 fullWidth
                  error={hasError('correo')}
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="String"
                  value={formState.values.correo || ''}
                  defaultValue="ejemplo@ejemplo.com"
                />
              </Grid>:<Grid item xs={12}>
              <InputLabel size="normal" id="cantidad-label"><h2 className={classes.h1} >Correo *</h2></InputLabel>
                <br></br>
                <TextField
                  placeholder="Correo"
                  label="Correo electronico *"
                  variant="filled"
                  size="medium"
                  name="correo"
                 // helperText={hasError('cantidad')}
                 fullWidth
                  error={hasError('correo')}
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="String"
                  value={formState.values.correo || ''}
                  defaultValue="ejemplo@ejemplo.com"

                />
                </Grid>}

      <br></br>
      <form method="post" action={URL}>
      <input name="merchantId"      type="hidden"  value="508029"  />
      <input name="accountId"       type="hidden"  value="512321" />
      <input name="description"     type="hidden"  value={formState.values.referenceCode}/>
      <input name="referenceCode"   type="hidden"  value={formState.values.referenceCode}/>
      <input name="amount"          type="hidden"  value={precioTotalProdSS}/>
      <input name="tax"             type="hidden"  value="0"/>
      <input name="taxReturnBase"   type="hidden"  value="0" />
      <input name="currency"        type="hidden"  value={moneda}/>
      <input name="signature"       type="hidden"  value={signature}/>
      <input name="test"            type="hidden"  value="0"/>
      <input name="buyerEmail"      type="hidden"  value={formState.values.correo}/>
      <input name="responseUrl"     type="hidden"  value="http://localhost:3000/respuestaPayU"/>
      <input name="confirmationUrl" type="hidden"  value="http://localhost:3000/respuestaPayU"/>
      <input name="shippingAddress"    type="hidden"  value={formState.values.direccion}   />
      <input name="shippingCity"       type="hidden"  value={formState.values.ciudad} />
      <input name="shippingCountry"    type="hidden"  value={formState.values.pais}  />
      {formState.isValid?<input name="Submit"          type="submit"  value="Enviar"/>:<></>}
     </form>
	 

              </div>

     </div>

);
};

export default PagosPayUReturn;
