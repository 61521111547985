import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { Button, Card, CardContent, colors, Container, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Form
} from './components';
import { Delete } from '@material-ui/icons';
import validate from 'validate.js';
import { sessionData } from 'utils/sessionData';
import Swal from 'sweetalert2';
import requestApi from 'utils/request';
import PedidosConfirmar from './../PedidosConfirmar';
import {
  Hidden,useMediaQuery
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CustomizedDialogs from 'views/CustomizedDialogs';
import CustomizedDialogsContrato from 'views/CustomizedDialogsContrato';
import Loading from 'views/Loading';
import Carrousel from 'views/Carrousel';
import PedidosForm from './PedidosForm';

const schema = {
  producto: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 300,
    },
  },
  cantidad: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 120,
    },
  },
  tipo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 120,
    },
  },
  direccion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 100,
    },
  },
  descripcion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 200,
    },
  },
  ciudad: {
    presence: { allowEmpty: false, message: 'es requerido' },
  },
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 200,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor:"#AB9ABD"
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  h1: {
    margin: "0 0 0 0",
  },
  select: {
    margin: "0 0 1em 0",
  }
}));

const Pedidos = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;

  const {values} = props.location.state;
  console.log("nombre producto----: "+values.nombre)

  console.log("nombre producto----: "+ JSON.stringify(values))
  // const [cards, setFormState] = React.useState(
  //   [1]
  // );

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
 

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        spacing={8}
        direction={isMd ? 'row' : 'column-reverse'}
        justifyContent='center'
        //alignItems='center'
      >
        <Grid 
          item
          //container
          //alignItems="center"
          justifyContent='up'
          //xs={4}
          data-aos={'fade-up'}
          >
          <Carrousel product={values}></Carrousel>
        </Grid>
        <Grid
          item
          //container
          //alignItems="t"
          //xs={6}
          data-aos={'fade-up'}
        >
          <PedidosForm product={values}></PedidosForm>

        </Grid>
      </Grid>
    </div>

  );
};

export default Pedidos;