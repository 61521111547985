import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { Icon, LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardPricingStandard } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeight900: {
    fontWeight: 900,
  },
}));

const Pricings = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        
        title={
          <span>
            Licencias
            <br />
            <Typography component="span" variant="inherit" color="primary">
              Tu producto debe contar con garantia y ser de óptima calidad.
            </Typography>
            <br></br>
            <Typography  variant="h4" component="h4" style={{color:"#ffffff"}}>
            Elige la que mas se ajuste a tu servicio, cambias cuando quieras
            </Typography>
          </span>
          
        }
        subtitle=""
        //ctaGroup={[
          //<LearnMoreLink title="Conoce nuestros dos tipos de licencias" href="" variant="h6" />,
        //]}
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            withShadow
            liftUp
            title="Licencia Estandar"
            subtitle="No tiene ningun costo"
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  $0
                </Typography>
              </div>
            }
            features={[
              'Servicio al cliente proveedor',
              '10% comision de Selpii por venta',
              'No contacto con cliente',
              'Acceso a pedidos',
              'Pagina web estandar',
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            }
            cta={
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                href="https://wa.me/3203500458"
              >
                Suscribete ahora
              </Button>
            }
            disclaimer="Libre"
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            title="Licencia premium"
            liftUp
            subtitle="Un bajo costo, mas ventajas"
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  US$25/mes
                </Typography>
              </div>
            }
            features={[
              'Notificacion de pedidos',
              'Marketing Digital',
              'Historial de clientes',
              'Atención al cliente',
              'Pagina web personalizada',
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            }
            cta={
              <Button color="primary" variant="outlined" fullWidth size="large" href="https://wa.me/3203500458">
                Suscribete ahora
              </Button>
            }
            disclaimer="30 días gratis para que pruebes, si no te gusta, cancelas cuando quieras"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Pricings.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Pricings;
