import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  button:{
    margin: "1em 1em 1em 1em",
   
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
//#PENDIENTE DAR MAS ESPACIO ENTRE COLUMNAS ESTAN MUY PEGADAS
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        spacing={12}
        direction={isMd ? 'row' : 'column-reverse'}
        columnSpacing={12}
        width="100%"
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          data-aos={'fade-up'}
          spacing={12}
        >
          <SectionHeader
            title={
              <span>
                Productos Orgánicos y Naturales
                <br />
                <Typography component="span" variant="inherit" color="primary">
                  Garantia y mejor calidad del mercado.
                </Typography>
                <br></br>
                <Typography  variant="h4" component="h4" style={{color:"#ffffff"}}>
                Todos queremos productos sanos, deliciosos y sin químicos, nuestro cuerpo lo merece.
                </Typography>
              </span>
              
            }
            subtitle=''
            ctaGroup={[
              <Button variant="contained" color="primary" size="large" href="/quienes-somos" >
                Saber más...
              </Button>,
              <Button sx={{margin: 2}} className={classes.button} variant="contained" color="secondary" size="large" href="/Album">
                Has tu pedido.
            </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          data-aos={'fade-up'}
          spacing={12}
        >
          <Image
            src="/images/illustrations/ProductosNaturales/frutas.gif"
            alt="TheFront Company"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
