import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  button:{
    margin: "1em 1em 1em 1em",
   
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={4}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span>
                ¿ Quienes Somos ?
                <br />
                <br></br>
                <Typography component="span" variant="inherit" color="primary">
                  Selpii.
                </Typography>
                <br></br>
                <br></br>
                <Typography  variant="h4" component="h4" style={{color:"#ffffff"}}>
                Esta es una empresa que esta en formación actualmente no esta constituida.
                </Typography>
                <br></br>
                <Typography  variant="h4" component="h4" style={{color:"#ffffff"}}>
                Desde el año 2020 se esta cosechando y vendiendo frutas como el mango Tommy, el limón Taithí,
                el Cacao, el platano, entre otros. Haciendo alianzas con otros productores del país se ha logrado crear una
                red única de productos naturales de la mas alta calidad aptos para exportación.
                </Typography>

              </span>
              
            }
            subtitle=''
            ctaGroup={[
              <Button variant="contained" color="primary" size="large" href="https://www.facebook.com/Selpii-Colombia-103972648327938" >
                Saber más...
              </Button>,
              <Button sx={{margin: 2}} className={classes.button} variant="contained" color="secondary" size="large" href="/Album">
                Has tu pedido.
            </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="/images/illustrations/mangoTommy/mangoTommy.gif"
            alt="TheFront Company"
            className={classes.image}
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
