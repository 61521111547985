import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconButton, Link, List, ListItem, Grid, useMediaQuery } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 0),
    },
    background: theme.palette.backgroundHome.footer,
    marginTop: '0em'
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '80%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    marginLeft:"5em",
    marginBottom:"2em"
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 100,
    height: 32,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  blanco: {
    fontSize: 12,
    color: 'white',
  },
}));

const Footer = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  console.log("background footer theme", clsx(classes.root))

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        container
        spacing={20}
        direction={isMd ? 'row' : 'column-reverse'}
      // alignContent='left'
      >
        <Grid
          item
          //container
          //alignItems="left"
          //justifyContent='left'
          //xs={4}
          data-aos={'fade-up'}>
          <div className={classes.footerContainer}>
            <List disablePadding>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <div className={classes.logoContainer}>
                  <a href="/" title="thefront">
                    <Image
                      className={classes.logoImage}
                      src="/images/logos/logo-negative.svg"
                      alt="thefront"
                      lazy={false}
                    />
                  </a>
                </div>
              </ListItem>
              <a className={classes.blanco}> Carrera 86a N 89 64</a><br />
              <a className={classes.blanco}> Cel: 3203500458</a><br />
              <a className={classes.blanco}> Selpii Productos y Servicios SAS.</a><br />
              <a className={classes.blanco}> Derechos Reservados copyright</a><br />
              <a className={classes.blanco}> Bogota D.C.</a><br />
              <ListItem disableGutters>
                <a href="https://www.facebook.com/Selpii-Colombia-103972648327938">
                  <IconButton className={classes.socialIcon}>
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                </a>
                <a href="https://www.instagram.com/selpii_colombia/?hl=es">
                  <IconButton className={classes.socialIcon}>
                    <InstagramIcon className={classes.icon} />
                  </IconButton>
                </a>
                <a href="https://twitter.com/SelpiiC">
                  <IconButton className={classes.socialIcon}>
                    <TwitterIcon className={classes.icon} />
                  </IconButton>
                </a>
              </ListItem>
            </List>
            
          </div>
          
        </Grid>
        <Grid
          item
          //container
          //alignItems="left"
          //justifyContent='left'
          //xs={4}
          data-aos={'fade-up'}>
          <Link  style={{ color: "#ffffff", fontWeight: "bold", marginLeft:"4em" }} href="/quienes-somos" variant="h4" color="secondary">¿ Quienes somos ?</Link>
        </Grid>
      </Grid>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
