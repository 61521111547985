/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useState, useEffect } from 'react';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listItemLink: {
    textDecoration: 'none',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  divider: {
    width: '100%',
  },
  loginUser: {
    width: '7em',
    height: '4em',
    marginLeft: '1em',
    marginRight: '1em',
    alignItems: 'center'
  },
}));

const SidebarNav = props => {
  const { onClose, className, ...rest } = props;
  const classes = useStyles();
  //session
  const [alertContent, setAlertContent] = useState('');
  const [alert, setAlert] = useState(false);

  const [correo, setCorreo] = useState('');


  useEffect(() => {
   
  console.log("useEffect outside");
  console.log("session Storage: "+sessionStorage.getItem('email'));
  setAlertContent(sessionStorage.getItem('email'));
  setAlert(false)
  if(alertContent !== null){
  setAlert(true)
  }
  console.log("alert: "+alert);
  console.log("alertContent: "+alertContent);
  
  });


  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={onClose}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography
          variant="h6"
          color="primary"
          component="a"
          href="/home"
          className={classes.listItemLink}
        >
          Inicio
        </Typography>
      </ListItem>
      <ListItem className={classes.listItem}>
      {alert ? <Typography
                  variant="h6"
                  color="primary"
                  component="a"
                  className={classes.listItemLink}
                  href="/log-out"
            >
              LogOut
            </Typography> : <Typography
          variant="h6"
          color="primary"
          component="a"
          href="/signup-simple"
          className={classes.listItemLink}
        >
          Registrate
        </Typography>
          }
        
      </ListItem>
      {alert ?  <a className={classes.loginUser} href="/pedidosUsuario"><Image
            className={classes.loginUser}
            src="/images/logos/logoIniciarSesion.png"
            alt="thefront"
            lazy={false}
            href="/pedidosUsuario"
          />
          <div> Bienvenido: {alertContent}</div>
          </a>:<ListItem className={classes.listItem}>
          <Typography
          variant="h6"
          color="primary"
          component="a"
          href="/Album"
          className={classes.listItemLink}
        >
              Iniciar Sesión
            </Typography>
          </ListItem>}
      <ListItem className={classes.listItem}>
        <Typography
          variant="h6"
          color="primary"
          component="a"
          href="/Album"
          className={classes.listItemLink}
        >
          Hacer Pedido
        </Typography>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography
          variant="h6"
          color="primary"
          component="a"
          href="/pedidosUsuario"
          className={classes.listItemLink}
        >
          Ver Pedidos
        </Typography>
      </ListItem>
      {/*
      <ListItem className={classes.listItem}>
        <Typography
          variant="h6"
          color="primary"
          component="a"
          href="/not-found"
          className={classes.listItemLink}
        >
          Error
        </Typography>
      </ListItem>
  */}
      <ListItem className={classes.listItem}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          component="a"
          target="blank"
          href="https://www.facebook.com/Selpii-Colombia-103972648327938"
        >
          Contactanos
        </Button>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default SidebarNav;
