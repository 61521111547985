import * as React from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);




const useStyles = makeStyles(theme => ({
  root: {},
  boxcolor:{
    color: "#4FEEEC",
    backgroundColor: "#AB9ABD",
    opacity: "0.8"
   
  },
  button:{
    margin: "1em 1em 1em 1em",
   
  },
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
    backgroundColor:"#AB9ABD"
  },
}));

function Carrousel({product}) {

  console.log("carrousel product: "+ product.nombre)

  const images = [
    {
      label: `${product.nombre_comercial}`,
      imgPath:
      `/images/illustrations/${product.nombre}/${product.nombre}0.jpeg`,
    },
    {
      label: `${product.nombre_comercial}`,
      imgPath:
      `/images/illustrations/${product.nombre}/${product.nombre}1.jpeg`,
    },
    {
      label: `${product.nombre_comercial}`,
      imgPath:
      `/images/illustrations/${product.nombre}/${product.nombre}2.jpeg`,
    },
    {
      label: `${product.nombre_comercial}`,
      imgPath:
      `/images/illustrations/${product.nombre}/${product.nombre}3.jpeg`,
    },
    {
      label: `${product.nombre_comercial}`,
      imgPath:
      `/images/illustrations/${product.nombre}/${product.nombre}4.jpeg`,
    },
    {
      label: `${product.nombre_comercial}`,
      imgPath:
      `/images/illustrations/${product.nombre}/${product.nombre}5.jpeg`,
    },
    {
      label: `${product.nombre_comercial}`,
      imgPath:
      `/images/illustrations/${product.nombre}/${product.nombre}0.jpeg`,
    },
  ];

  
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const classes = useStyles()

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }} backgroundColor="#AB9ABD">
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: '#AB9ABD',
        }}
      >
        <Box className={classes.boxcolor}>
        <Typography variant="h4" component="span" style={{color:"#3f51b5", fontWeight:"bold" }}>{images[activeStep].label}</Typography>
        </Box>
        
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        backgroundColor="#AB9ABD"
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 400,
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        color='#AB9ABD'
        className={classes.boxcolor}
        backgroundColor="#AB9ABD"
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <br></br>
      <Button variant="contained" color="primary" size="small" href="/mangoTommy" >Ver Mas</Button>
      <br></br>
      <br></br>
      <Typography style={{ color: '#000000', fontWeight: 'bold' }}>
                      Este producto se encuentra disponible. {images[activeStep].label} de la mejor calidad.
                      Garantia de entrega, devolución y satisfacción del producto.
                    </Typography>
    </Box>
  );
}

export default Carrousel;