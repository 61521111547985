import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { Icon, LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardPricingStandard } from 'components/organisms';
import { CardPricingStandardA } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeight900: {
    fontWeight: 900,
  },
  colorA: {
    minWidth: 275,
    border: `2px solid red`,
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  }
}));

const Pricings = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="Tipos de pedidos"
        subtitle="En tu pedido podras elegir la que mas se ajuste a tu servicio"
        ctaGroup={[
          <LearnMoreLink title="Conoce nuestros dos sistemas de suministro" href="/mango-tommy-modos" variant="h6" />,
        ]}
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            withShadow
            liftUp
            title="Suministro Estandar"
            subtitle="No tiene ningun costo"
            color="F8D1C9"
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  $0
                </Typography>
              </div>
            }
            features={[
              'Precio del dia de Plaza',
              'Tiempo de Pago 2 días ',
              'Tiempo de entrega 5 días',
              'Mango de primera',
              'Servicio de PQRs',
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            }
            cta={
              <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                href="/signup-simple"
              >
                Pruebalo ahora
              </Button>
            }
            disclaimer="Libre"
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <CardPricingStandardA
            variant="outlined"
            title="Suministro por Contrato"
            liftUp
            subtitle="Te comprometes a compra por contrato, mas ventajas"
            priceComponent={
              <div>
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.fontWeight900}
                >
                  $0
                </Typography>
              </div>
            }
            features={[
              'Precio acordado',
              'Pago acordado',
              'Tiempo de entrega 2 días',
              'Mango tipo exportación',
              'Asesoria legal incluida',
              'Servicio de PQRs',
            ]}
            featureCheckComponent={
              <Icon
                fontIconClass="far fa-check-circle"
                fontIconColor={theme.palette.primary.main}
              />
            }
            cta={
              <Button color="primary" variant="outlined" fullWidth size="large"
              href="/signup-simple">
                Pruebalo ahora
              </Button>
            }
            disclaimer="Recuerda que una vez firmado el contrato, adquieres un compromiso legal"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Pricings.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Pricings;
