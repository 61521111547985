import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Info?
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Política de precios
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
          Recuerda que el valor total de tu pedido se calcula automátiacmente según cantidad y el precio del producto.
          Si tu producto es para un país diferente a Colombia, el precio final puede variar, un asesor se comunicará
          contigo para acordar el precio y terminos de contrato.
          </Typography>
          <br></br>
          <br></br>
          <Typography gutterBottom>
          Los precios en Colombia pueden ser consultados en:
          </Typography>
          <br></br>
            <br></br>
             { /*<Image src="/images/illustrations/mangoTommy/DaneBoton.png" />*/}
            
              <Link variant="contained" href="https://www.dane.gov.co/index.php/estadisticas-por-tema/agropecuario/sistema-de-informacion-de-precios-sipsa/componente-precios-mayoristas">
                PRECIOS OFICIALES DANE</Link>
                <br></br >
                <br></br>
                <Typography gutterBottom>
              O en la página web principal de la plaza de cada ciudad, en bogotá por ejemplo:
            </Typography>
            <br></br>
            <br></br>
              <Link variant="outlined" href="https://boletin.precioscorabastos.com.co/">PRECIO BOGOTA</Link>
              <br></br >
                <br></br>
                <Typography gutterBottom>
              Recuerda que los precios en tipo de compra con contrato se acuerdan con servicio al cliente despues de realizado el pedido.
            </Typography>
            <Link variant="outlined" href="/mango-tommy-modos">Compra con contrato</Link>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Entendido
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};


