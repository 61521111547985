import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import validate, { async } from 'validate.js';
import requestApi from '../../../../utils/requestPayU';
import { sessionData } from 'utils/sessionData';
import { Redirect, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Image } from 'components/atoms';
import { useState, useEffect } from 'react';
import Loading from 'views/Loading';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  logoImage: {
    width: '20em',
    height: '5em',
  },
  payu: {
    width: '30em',
    height: '20em',
    marginLeft: '1em',
    marginRight: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5em',  
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  payuButton: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
    },
  },
  asesorButton: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
    },
  },
  qr: {
    //width: '30em',
    height: '18em',
    marginLeft: '1em',
    marginRight: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5em', 
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
    }, 
  },
  contra: {
    //width: '30em',
    height: '30em',
    marginLeft: '1em',
    marginRight: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em', 
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
    }, 
  },
  buttonPadding: {    
    padding: '0em',  
    width: '0em', 
  },
}));

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  contrasena: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 8,
    },
  },
};
//#PENDIENTE OK Agregar forma de pago contra entrega
function Form({product}) {
  const classes = useStyles();

  // const [cards, setFormState] = React.useState(
  //   [1]
  // );

  //const {values} = props.location.state;

  console.log("value from: " + Object.values(product))

 console.log("pedido pagos: "+product);

  const pedido = Object.values(product);

  const history = useHistory();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(false);
  const [alertContent, setAlertContent] = useState('Ingrese sus datos');

  const [alertSeverity, setAlertSeverity] = useState('info');


  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  
const handlePayment = () => {
  console.log("productos del carro:" + pedido)

  /*requestApi(pedido).then(async function (response) {
    // debugger "/pagosCodigoQr"
     console.log("response html payu: "+response)
      
       }).then(result => {
         if (result.isConfirmed) {
           window.location.replace("https://wa.me/3203500458")
         }
         history.push("/pedidosUsuario");
       })*/
     
  
  //aca se envia el nombre del producto
  history.push('/pagosPayUReturn', { values: product })
};


const handlePaymentQr = () => {
  console.log("productos del carro:" + pedido)  
  //aca se envia el nombre del producto
  history.push('/pagosCodigoQr', { values: product })
};

const handlePaymentContraEntrega = () => {
  console.log("productos del carro:" + pedido)
  history.push('/pagosContraEntrega', { values: product })
};




 

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
        <Grid container spacing={4} m={2} pt={3}>
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
            m={2} pt={3}
          >
             <Button className={classes.payuButton} onClick={handlePayment} title="thefront">
            <Image
              className={classes.payu}
              src="/images/logos/payU.png"
              alt="thefront"
              lazy={false}
            />
            </Button>
          </Grid>
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}>
               <Button onClick={handlePaymentQr} title="thefront">
            <Image
              className={classes.qr}
              src="/images/illustrations/qrpagos.png"
              alt="thefront"
              lazy={false}
       
            />
            </Button>
          </Grid>
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}>
               <Button onClick={handlePaymentContraEntrega} title="thefront">
            <Image
              className={classes.contra}
              src="/images/illustrations/pagosContraEntrega.png"
              alt="thefront"
              lazy={false}
       
            />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2" justifyContent="center" align='center'>
                Elige una forma de pago.
              </Typography>
            </i>
          </Grid>
          <br></br>
          <br></br>
          </Grid>
          <Button
            size="large"
            variant="contained"
            href="https://wa.me/3203500458"
            color="primary"
            fullWidth
            justifyContent="center"
            alignItems="center"
            className={classes.asesorButton}
          >
            Deseo hacer la compra con un asesor.
          </Button>
       
    </div>
  );
};

export default Form;
