import React from "react";
import ReactWhatsappButton from "react-whatsapp-button";
//import { FloatingWhatsApp } from 'react-floating-whatsapp'

function WhatsupBotton() {
  return (
    <div className="App">
    <ReactWhatsappButton
      countryCode="57"
      phoneNumber="3203500458"
      div="Contactanos al whatsup"
      className
    />
  </div>
   
  )
}

export default WhatsupBotton;