import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { Image } from 'components/atoms';
import { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Carrito } from 'views';
import CarritoIcon from './CarritoIcon';



const useStyles = makeStyles(theme => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    backgroundColor: "#FFFFFF"
  },
  listItem: {
    cursor: 'pointer',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemText: {
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  listItemTextMango: {
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    fontWeight: 'bold',
    color: '#194D33',
    size: '1em'
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 32,
    },
  },
  logoImage: {
    width: '20em',
    height: '5em',
  },
  loginUser: {
    width: '7em',
    height: '4em',
    marginLeft: '1em',
    marginRight: '1em',
    alignItems: 'center'
  },
}));



const Topbar = props => {
  const { onSidebarOpen, ...rest } = props;

  const classes = useStyles();
  const [alertContent, setAlertContent] = useState('');
  const [alert, setAlert] = useState(false);

  const [correo, setCorreo] = useState('');


  useEffect(() => {
   
  console.log("useEffect outside");
  console.log("session Storage: "+sessionStorage.getItem('email'));
  setAlertContent(sessionStorage.getItem('email'));
  setAlert(false)
  if(alertContent !== null){
  setAlert(true)
  }
  console.log("alert: "+alert);
  console.log("alertContent: "+alertContent);
  
  });


  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="thefront">
          <Image
            className={classes.logoImage}
            src="/images/logos/logo.svg"
            alt="thefront"
            lazy={false}
            width={20}
          />
        </a>
      </div>
      <div className={classes.flexGrow} />
      <Hidden smDown>
        <List className={classes.navigationContainer}>
         { /*
         <ListItem className={classes.listItem}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.listItemTextMango}
              component="a"
              href="/home"
            >
              Inicio
            </Typography>
          </ListItem> 
            <ListItem className={classes.listItem}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.listItemTextMango}
              component="a"
              href="/Album"
            >
              Pedidos
            </Typography>
          </ListItem>
          */
  }
  <ListItem className={classes.listItem}>
            <CarritoIcon></CarritoIcon>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.listItemTextMango}
              component="a"
              href="/Album"
            >
              Productos
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.listItemTextMango}
              component="a"
              href="/pedidosUsuario"
            >
              Ver pedidos
            </Typography>
          </ListItem>
          {
          /*
          <ListItem className={classes.listItem}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.listItemText}
              component="a"
              href="/presentation"
            >
              Portal
            </Typography>
          </ListItem>
          */
          }
             
             {alert ? <Typography
              variant="h6"
              color="textSecondary"
              className={classes.listItemTextMango}
              component="a"
              href="/log-out"
            >
              LogOut
            </Typography> :<></>
          }
           {alert ?  <a className={classes.loginUser} href="/pedidosUsuario"><Image
            className={classes.loginUser}
            src="/images/logos/logoIniciarSesion.png"
            alt="thefront"
            lazy={false}
            href="/pedidosUsuario"
          />
          <div> Bienvenido: {alertContent}</div>
          </a>:<ListItem className={classes.listItem}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.listItemTextMango}
              component="a"
              href="/signin-simple"
            >
              Iniciar Sesión
            </Typography>
          </ListItem>}
        
          <ListItem className={classes.listItem}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              component="a"
              target="blank"
              href="https://www.facebook.com/Selpii-Colombia-103972648327938"
              className={classes.listItemButton}
            >
              Contactanos
            </Button>
          </ListItem>
        </List>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          className={classes.iconButton}
          onClick={onSidebarOpen}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
