import React from "react";
import ReactWhatsappButton from "react-whatsapp-button";

function WhatsupBotton() {
  return (
    <div className="App">
      <ReactWhatsappButton
        countryCode="57"
        phoneNumber="3203500458"
      />
    </div>
  )
}

export default WhatsupBotton;