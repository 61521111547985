import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from 'components/molecules';
import { Button, Card, CardContent, colors, Container, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import validate from 'validate.js';
import Swal from 'sweetalert2';
import requestApi from 'utils/request';
import { useState, useEffect, useLocation } from 'react';
import CustomizedDialogs from 'views/CustomizedDialogs/CustomizedDialogs';
import { useHistory } from 'react-router-dom';

const schema = {
  producto: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 300,
    },
  },
  cantidad: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 120,
    },
  },
  direccion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 100,
    },
  },
  descripcion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 200,
    },
  },
  ciudad: {
    presence: { allowEmpty: false, message: 'es requerido' },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor:"#AB9ABD"
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

//function PedidosConfirmar(props) {
const PedidosConfirmar = (props) => {
  const classes = useStyles();
 
const {values} = props.location.state;
 
  console.log("pedido cantidad: "+ values.cantidad);
  console.log("pedido producto: "+ values.producto);

  useEffect(() => {
   
}, []);
  // const [cards, setFormState] = React.useState(
  //   [1]
  // );

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {id_usuario: sessionStorage.email},
    touched: {},
    errors: {},
  });

  const [sesion, setSesion] = useState('');
  const [sesionB, setSesionB] = useState(false);
  const [tipoB, setTipoB] = useState(false);

  useEffect(() => {
    setSesion(sessionStorage.getItem('correo'));
    console.log("sesion: "+sesion)
    if (sesion !== null) {
      setSesionB(false)
    } 
    if(values.tipo==="con")
    {
    setTipoB(true);
    }
   // let history = useHistory();
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const addCard = id => {
    let newId = id + 1;
    if (cards.includes(newId)) {
      addCard(newId * 2);
    } else {
      setFormState([...cards, newId]);
    }
  }

  let history = useHistory();
  const handleSubmit = async event => {
    event.preventDefault();

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
    if(sessionStorage.getItem("correo")!==null){values.id_usuario = sessionStorage.getItem("correo");}
    else{values.id_usuario=values.correo}
    
    console.log("handleSubmit confirmarPedido")
    console.log("values: "+values.id_usuario)
    requestApi("create", "pedidos", values).then(async function (response) {
       // debugger
        if(response.status == 200 && response.data.result==="OK") {
          Swal.fire({
            title: 'Pedido registrado',
            icon: 'success',
            html: 'En los próximos dos días hábiles un asesor se comunicará contigo para realizar la entrega',
            showCancelButton: true,
            confirmButtonText:
              '<i class="fa fa-whatsapp"></i> Contactar',
            cancelButtonText:
              'Cerrar',  
          }).then(result => {
            if (result.isConfirmed) {
              window.location.replace("https://wa.me/3203500458")
            }
            console.log("then result");
            history.push("/pedidosUsuario");
          })
          console.log("if-----");
           // history.push("/pedidosUsuarios");
        }else{
          Swal.fire({
            title: 'Pedido No registrado',
            icon: 'success',
            html: 'Su pedido No ha sido registrado con exito, Intente nuevamente o comuniquese al whatsup: '+ response.data.result,
            showCancelButton: true,
            confirmButtonText:
              '<i class="fa fa-whatsapp"></i> Contactar',
            cancelButtonText:
              'Cerrar',
          }).then(result => {
            if (result.isConfirmed) {
              window.location.replace("https://wa.me/3203500458")
            }
            history.push("/pedidosUsuario");
          })
        }
        //history.push("/pedidosUsuarios");
       
        }
        
       // history.push("/pedidos");
      )
     
  };

  const removeCard = id => {
    console.log(id);
    console.log(cards);
    setFormState(cards.filter(item => item !== id))
  }

  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
        <Typography component="h2" variant="h2" color="primary" align='center'>
                 VERIFICA TU PEDIDO
            </Typography>
          <SectionHeader
            
            title="Confirma los datos de tu pedido"
            subtitle="Una vez confirmes tu pedido si deseas cancelarlo, comunicate con atencion al cliente"
          />
          <div className={classes.root}>
            <form name="pedidos-form" method="post" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel id="producto-select-label">Producto</InputLabel>
                    <TextField
                    placeholder="Producto"
                    variant="outlined"
                    size="normal"
                    name="cantidad"
                    fullWidth
                    value={values.producto}
                  />    
                </Grid>
                <Grid item xs={12}>
                <InputLabel id="producto-select-label">Correo</InputLabel>
                <TextField
                    placeholder="Correo o Teléfono"
                    variant="outlined"
                    size="medium"
                    name="correo"
                    fullWidth
                    value={values.correo}
                  />
                </Grid>
                <Grid item xs={12}>
                <InputLabel id="producto-select-label">Cantidad</InputLabel>
                    <TextField
                    placeholder="Cantidad"
                    variant="outlined"
                    size="medium"
                    name="cantidad"
                    fullWidth
                    value={values.cantidad}
                  />    
                </Grid>
                <Grid item xs={12}>
                <InputLabel id="producto-select-label">Lugar</InputLabel>
                    <TextField
                    placeholder="Cantidad"
                    variant="outlined"
                    size="medium"
                    name="cantidad"
                    fullWidth
                    value={values.pais+"-"+values.ciudad+"-"+values.estado}
                  />  
                </Grid>
                
                <Grid container justifyContent="flex-end"> <CustomizedDialogs></CustomizedDialogs></Grid>
                {tipoB?<Grid item xs={12}>
                <InputLabel id="producto-select-label">Precio</InputLabel>
                    <TextField
                    placeholder="Cantidad"
                    variant="outlined"
                    size="medium"
                    name="cantidad"
                    fullWidth
                    value="Servicio al cliente se comunicará contigo para acordar el precio"
                  />  
                </Grid>:
                <Grid item xs={12}>
                <InputLabel id="producto-select-label">Precio</InputLabel>
                    <TextField
                    placeholder="Cantidad"
                    variant="outlined"
                    size="medium"
                    name="cantidad"
                    fullWidth
                    value={values.precio}
                  />  
                </Grid>}
                <Grid item xs={12}>
                <InputLabel id="producto-select-label">Direccion</InputLabel>
                    <TextField
                    placeholder="direccion"
                    variant="outlined"
                    size="medium"
                    name="direccion"
                    fullWidth
                    value={values.direccion}
                  />  
                </Grid>
                <Grid item xs={12}>
                <InputLabel id="producto-select-label">Descripción</InputLabel>
                    <TextField
                    placeholder="descripcion"
                    variant="outlined"
                    size="medium"
                    name="descripcion"
                    fullWidth
                    value={values.descripcion}
                  />  
                </Grid>
                <Grid item xs={12}>
                  <i>
                    <Typography variant="subtitle2">
                      Verifica los datos y confirma tu pedido.
                    </Typography>
                  </i>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Confirmar Pedido
                  </Button>
                </Grid>
                <Grid item xs={12}>              
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    href="/Album"
                  >
                   Cancelar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Section>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default PedidosConfirmar;



{/* <Container>
  <Grid container spacing={2}>
    {cards.map((id) => {
    return (
      <Grid container justifyContent='center' item sm={4} lg={6}>
        <Card>
          {id}
          <CardContent>
            <Form></Form>
          </CardContent>
        </Card>
        <IconButton onClick={() => removeCard(id)} aria-label="delete">
          <DeleteIcon/>
        </IconButton>
      </Grid>
      );
    })}            
    <Grid item
      container
      justifyContent='center'
      alignItems="center" sm={4} lg={6}>
      <IconButton onClick={() => addCard(cards.length)} size="large" aria-label="add">
        <ControlPointIcon  fontSize="large"/>
      </IconButton>
    </Grid>
  </Grid>
</Container> */}