import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader, CountUpNumber } from 'components/molecules';
import Carrousel from 'views/Carrousel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  placementGrid: {
    display: 'flex',
  },
  placementGridItemMiddle: {
    margin: `0 ${theme.spacing(3)}px`,
  },
  coverImage: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Features = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={4}>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >"
          <Carrousel product="mangoTommy"></Carrousel>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader
                title={
                  <span>
                    Descripción del producto
                    <br /><br />
                    <Typography component="span" variant="h4" color="primary" href="/pedidos">
                      <a href="/album">El mango tommy es una de las variedades de mango mas apetecidas,
                      se cultiva con la menor cantidad de quimicos, se usan insecticidas naturales comprobados,
                      que aseguran una cosecha sana y del mejor sabor.</a>
                    </Typography>
                    <br /><br />
                    <Typography variant="h5" component="h4" style={{color:"#ffffff"}}>
               Te guiaremos en todo el proceso para que realices tu pedido fácil y rápido.
              </Typography>
                  </span>
                }
                
          
              />
                <br /><br />
                    <Button color="primary" variant="outlined" fullWidth size="large" href="/Album">
                Has tu pedido
              </Button>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h4" component="h4" style={{color:"#000000"}}>
                      Este producto es apto para exportación, cuando hagas tu pedido
                       selecciona tu país y se te dará un precio estimado de venta. Un asesor se comunicará
                       contigo para realizar el tramite de exportación.
                    </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Features;
