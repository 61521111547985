/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';
import MainLayout from './layouts/Main';
import MinimalLayout from './layouts/Minimal';


const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/Home" />
  },
  //home
  {
    path: '/home',
    component: MainLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('./views/Home'))
      },
      {
        path: '/signup-simple',
        exact: true,
        component: lazy(() => import('views/SignupSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //mangoTommy
  {
    path: '/mangoTommy',
    component: MainLayout,
    routes: [
      {
        path: '/mangoTommy',
        exact: true,
        component: lazy(() => import('./views/MangoTommy'))
      },
      {
        path: '/signup-simple',
        exact: true,
        component: lazy(() => import('views/SignupSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //limonTahiti
  {
    path: '/limonTahiti',
    component: MainLayout,
    routes: [
      {
        path: '/limonTahiti',
        exact: true,
        component: lazy(() => import('./views/LimonTahiti'))
      },
      {
        path: '/signup-simple',
        exact: true,
        component: lazy(() => import('views/SignupSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //cacao
  {
    path: '/cacap',
    component: MainLayout,
    routes: [
      {
        path: '/cacao',
        exact: true,
        component: lazy(() => import('./views/Cacao'))
      },
      {
        path: '/signup-simple',
        exact: true,
        component: lazy(() => import('views/SignupSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //platano
  {
    path: '/platano',
    component: MainLayout,
    routes: [
      {
        path: '/platano',
        exact: true,
        component: lazy(() => import('./views/Platano'))
      },
      {
        path: '/signup-simple',
        exact: true,
        component: lazy(() => import('views/SignupSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //mangoTommyModos
  {
    path: '/mango-tommy-modos',
    component: MainLayout,
    routes: [
      {
        path: '/mango-tommy-modos',
        exact: true,
        component: lazy(() => import('./views/MangoTommyModos'))
      },
      {
        path: '/signup-simple',
        exact: true,
        component: lazy(() => import('views/SignupSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //mangoTommyPedidos
  {
    path: '/pedidos',
    component: MainLayout,
    routes: [
      {
        path: '/pedidos',
        exact: true,
        component: lazy(() => import('./views/Pedidos'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //mangoTommyPedidosCOnfirmar
  {
    path: '/pedidosConfirmar',
    component: MainLayout,
    routes: [
      {
        path: '/pedidosConfirmar',
        exact: true,
        component: lazy(() => import('./views/PedidosConfirmar'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //mangoTommyPedidosCOnfirmar
  {
    path: '/pedidosUsuario',
    component: MainLayout,
    routes: [
      {
        path: '/pedidosUsuario',
        exact: true,
        component: lazy(() => import('./views/PedidosUsuario'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //Cannabis
  {
    path: '/aceiteCannabis',
    component: MainLayout,
    routes: [
      {
        path: '/aceiteCannabis',
        exact: true,
        component: lazy(() => import('./views/Cannabis'))
      },
      {
        path: '/aceiteCannabis',
        exact: true,
        component: lazy(() => import('views/Cannabis'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //Carrito
  {
    path: '/carrito',
    component: MainLayout,
    routes: [
      {
        path: '/carrito',
        exact: true,
        component: lazy(() => import('./views/Carrito'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //pagosPayU
  {
    path: '/pagosPayU',
    component: MainLayout,
    routes: [
      {
        path: '/pagosPayU',
        exact: true,
        component: lazy(() => import('./views/PagosPayU'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //RespuestaPayu
  {
    path: '/respuestaPayU',
    component: MainLayout,
    routes: [
      {
        path: '/respuestaPayU',
        exact: true,
        component: lazy(() => import('./views/RespuestaPayU'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //pagosCodigoQr
  {
    path: '/pagosCodigoQr',
    component: MainLayout,
    routes: [
      {
        path: '/pagosCodigoQr',
        exact: true,
        component: lazy(() => import('./views/PagosCodigoQR'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //pagosContraEntrega
  {
    path: '/pagosContraEntrega',
    component: MainLayout,
    routes: [
      {
        path: '/pagosContraEntrega',
        exact: true,
        component: lazy(() => import('./views/PagosContraEntrega'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //pagosPayURequest
  {
    path: '/pagosPayUReturn',
    component: MainLayout,
    routes: [
      {
        path: '/pagosPayUReturn',
        exact: true,
        component: lazy(() => import('./views/PagosPayUReturn'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //Album
  {
    path: '/album',
    component: MainLayout,
    routes: [
      {
        path: '/album',
        exact: true,
        component: lazy(() => import('./views/Album'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //Album
  {
    path: '/quienes-somos',
    component: MainLayout,
    routes: [
      {
        path: '/quienes-somos',
        exact: true,
        component: lazy(() => import('./views/QuienesSomos'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //signUp
  {
    path: '/signup-simple',
    component: MainLayout,
    routes: [
      {
        path: '/signup-simple',
        exact: true,
        component: lazy(() => import('views/SignupSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //signIn
  {
    path: '/signin-simple',
    component: MainLayout,
    routes: [
      {
        path: '/signin-simple',
        exact: true,
        component: lazy(() => import('views/SigninSimple'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //slogOut
  {
    path: '/log-out',
    component: MainLayout,
    routes: [
      {
        path: '/log-out',
        exact: true,
        component: lazy(() => import('views/LogOut'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  //errors
  {
    path: '/not-found',
    component: MinimalLayout,
    routes: [
      {
        path: '/not-found',
        exact: true,
        component: lazy(() => import('./views/NotFound'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/home',
        exact: true,
        component: lazy(() => import('views/Home'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar'))
      },
      {
        path: '/changelog',
        exact: true,
        component: lazy(() => import('views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        component: lazy(() => import('views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('views/Mail'))
      },
      {
        path: '/management/customers',
        exact: true,
        component: lazy(() => import('views/CustomerManagementList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('views/ProjectManagementList'))
      },
      {
        path: '/management/orders',
        exact: true,
        component: lazy(() => import('views/OrderManagementList'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/presentation',
        exact: true,
        component: PresentationView
      },
      {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        component: lazy(() => import('views/ProjectCreate'))
      },
      {
        path: '/projects/:id',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('views/ProjectList'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/social-feed',
        exact: true,
        component: lazy(() => import('views/SocialFeed'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('views/GettingStarted'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
