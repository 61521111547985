import { makeStyles } from '@material-ui/core/styles';
import validate from 'validate.js';
import requestApi from 'utils/request';
import { Link, useHistory } from 'react-router-dom';
import { TableCell } from '@mui/material';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect, useLocation } from 'react';
import { Typography, IconButton, Grid, InputLabel, TextField, Button, Hidden } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Section, SectionAlternate } from 'components/organisms';
import Loading from 'views/Loading';
import Swal from 'sweetalert2';

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 200,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor:"#AB9ABD",
    marginTop:"1em"
  },
  formContainer: {
    height: '100%',
    display: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
    marginTop:"1em"
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  palette: {
    tomato: '#FF6347',
    pink: {
      deep: '#FF1493',
      hot: '#FF69B4',
      medium: '#C71585',
      pale: '#DB7093',
      light: '#FFB6C1',
    },
  },
}));

const theme = createTheme({
  palette: {
    tomato: '#FF6347',
    pink: {
      deep: '#FF1493',
      hot: '#FF69B4',
      medium: '#C71585',
      pale: '#DB7093',
      light: '#FFB6C1',
    },
  },
});

const PedidosUsuario = () => {
  const classes = useStyles();


  
  const [formStateV, setFormStateV] = useState({
    values: {correo:sessionStorage.email},
  });
  const [modifiedData,setModifiedData] = useState({
   
  });
  const [pedidosData,setPedidosData] = useState([]
   );

  const [sesion, setSesion] = useState('');
  const [sesionB, setSesionB] = useState(true);
  const [perfilB, setPerfilB] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadA, setLoadA] = useState(false);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {correo: sessionStorage.email},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);


  useEffect(() => {
    //setLoad(true);
    let sesionS = "";
    sesionS = sessionStorage.getItem('email');
    
    if (sesionS !== null) {
      setLoad(true)
      setLoadA(true)
      console.log("sesion value: 1= null")
    requestApi("getScan", "usuarios",formStateV.values).then(response => {
      setModifiedData([...response.data.result]);
      setPerfilB(true)
      setLoadA(false);
    })
    requestApi("getScan", "pedidos",formStateV.values).then(response => {
      setPedidosData([...response.data.result]);
      setLoad(false);
      
    })

    console.log("modifuedData: "+ modifiedData.getItem)
  }
    
    console.log("sesion value: "+ sesionS)
    console.log("sesionStorage value: -"+ sessionStorage.getItem('email') + "-")
    //Estoy trabajando en esta validacion 10192023
    if (sesionS === null || sesionS === undefined || sesionS ==="") {
      console.log("sesionB false")
      setSesionB(false)
    } 

    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

  }, [formState.values])



  



  

  const [Data,setData]=useState({
    correo:'',
})




  let history = useHistory();


  const pushFunction = () => {
    console.log("formState: "+formState);
    
    //console.log("formvalue touched correo " + formState.touched[field])
    //console.log("formvalue errors correo " + formState.errors[field])
    
    console.log("form values request is valid?: "+formState.isValid);
    if (formState.isValid) {
      setLoad(true)
      console.log("form values is valid, values: "+formState.values.correo)
      let correoS = formState.values.correo;
      console.log("correoS: "+ correoS)
      let correoField = "correo";
    //history.push('/pedidosConfirmar', { uid: "test props"})
    //history.push('/pedidosConfirmar', {producto:formState.values.producto})

    setData({correo:correoS})
    const d = { "correo": correoS }

    const myJSON = {correo: correoS};
     //let myJSON ="";
    try {
      //myJSON2 = JSON.parse({ correo: correoS });
    } catch (err) {
      // ⛔️ Uncaught SyntaxError: JSON.parse: unexpected character at
      // line 1 column 2 of the JSON data
      console.log("error parseJSON: "+err.message);
    }

    setLoad(true)
    console.log("form values request is valid, values Json: "+myJSON.correo)
    requestApi("getScan", "pedidos",myJSON).then(response => {
      setPedidosData(response.data);
  

     // console.log("result size: "+pedidosData)
     console.log("result size: "+ response.data.result.length)
 

      if (response.status == 200 && response.data.result.length>0) {
      setPedidosData([...response.data.result]);
      setPerfilB(false)
     setSesionB(true)
     setLoad(false)
      }else{
        console.log("No hay datos para ese correo o movil")
        setLoad(false)
        Swal.fire({
          title: 'Ocurrio un error',
          text: "No hay pedidos asociados a su busqueda "+response.data.result,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Entendido'
        })
      }
    })
     
    }
}





    const columns = [
     // { field: 'id', headerName: 'ID', width: 70 },
      { field: 'nombres', headerName: 'Nombres', width: 200 },
      { field: 'apellidos', headerName: 'Apellidos', width: 130 },
      { field: 'celular', headerName: 'Celular', width: 150 },
      { field: 'ciudad', headerName: 'Ciudad', width: 150 },
      { field: 'correo', headerName: 'Correo', width: 170 },
      { field: 'direccion', headerName: 'Dirección', width: 180 },
      { field: 'seudonimo', headerName: 'Seudonimo', width: 150 },
    ];
  
    const columnsPedidos = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'descripcion', headerName: 'Descripcion', width: 200 },
      { field: 'tipo', headerName: 'Tipo', width: 130 },
      { field: 'id_usuario', headerName: 'Correo', width: 150 },
      { field: 'ciudad', headerName: 'Ciudad', width: 150 },
      { field: 'direccion', headerName: 'Dirección', width: 180 },
      { field: 'producto', headerName: 'Producto', width: 150 },
      { field: 'cantidad', headerName: 'cantidad', width: 150 },
      { field: 'fecha_pedido', headerName: 'Fecha_pedido', width: 150 },
      { field: 'fecha_entrega', headerName: 'Fecha_entrega', width: 150 },
      { field: 'estado', headerName: 'Estado', width: 150 },
    ];
    
    const rows = [
      { id: 1, apellidos: 'Snow', ciudad: 'Jon' ,hola:'oe'},
      { id: 2, apellidos: 'Snow', ciudad: 'Jon' },
    ];
    


    const hasError = field =>{
      console.log("has error field "+field+ " error "+formState.errors[field])
    formState.touched[field] && formState.errors[field] ? true : false;
  
    }

   

    const hasErrorTest3 = field =>{
      let result  = formState.touched[field] && formState.errors[field] ? false : true;
  
      console.log("field: "+field + " Result: "+result)
      console.log(" fieldTouched: "+ formState.touched[field] + " fieldErrors: "+ formState.errors[field])
    }



    const handleChange = event => {
      event.persist();
      console.log("change form pedidos")
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));

    };
    
      return (
        <div className={classes.root}>
        <br></br>
          <Section className={classes.section}>
        <div >
        {sesionB ? 
        <Grid item xs={12}>
          {loadA?<Loading></Loading>:<></>}
          {perfilB && <Hidden perfilB>
        <Typography
          variant="h2"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Información de la cuenta.
        </Typography>
        <br></br>
        <br></br>
        <Typography
          variant="h4"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Nombre:
        </Typography>
        <div>
        {
      modifiedData.map((item)=><p>{item.nombres}</p>)
          }
     </div>
     <br></br>
        <Typography
          variant="h4"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Apellidos:
        </Typography>
        <div>
        {
      modifiedData.map((item)=><p>{item.apellidos}</p>)
          }
     </div>
     <br></br>
        <Typography
          variant="h4"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Número de Celular:
        </Typography>
        <div>
        {
      modifiedData.map((item)=><p>{item.celular}</p>)
          }
     </div>
     <br></br>
        <Typography
          variant="h4"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Ciudad:
        </Typography>
        <div>
        {
      modifiedData.map((item)=><p>{item.ciudad}</p>)
          }
     </div>
     <br></br>
        <Typography
          variant="h4"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Correo:
        </Typography>
        <div>
        {
      modifiedData.map((item)=><p>{item.correo}</p>)
          }
     </div>
     <br></br>
        <Typography
          variant="h4"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Dirección:
        </Typography>
        <div>
        {
      modifiedData.map((item)=><p>{item.direccion}</p>)
          }
     </div>
     <br></br>
        <Typography
          variant="h4"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
          Seudonimo:
        </Typography>
        <div>
        {
      modifiedData.map((item)=><p>{item.seudonimo}</p>)
          }
     </div>
        </Hidden>}
        
        <br></br>
        <br></br>
        <Typography
          variant="h2"
          color="primary"
          className={classes.listItemText}
          component="h2"
        >
         Historial de pedidos.
        </Typography>
        <br></br>
        <br></br>
        <DataGrid
          rows={pedidosData}
          columns={columnsPedidos}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
        </Grid>
      :
      <div className={classes.formContainer}> 
      <Grid item xs={12}>
        {load?<Loading></Loading>:<></>}
      <InputLabel size="normal" id="correo-label"><h1 className={classes.h1} >INGRESE SU CORREO O NUMERO DE CELULAR POR FAVOR *</h1></InputLabel>
      <TextField
          placeholder="Correo "
          label="Correo *"
          variant="filled"
          size="medium"
          name="correo"
          fullWidth
          onChange={handleChange}
          type="string"
          error={hasErrorTest3('correo')}
          //helperText={hasError('correo') ? formState.errors.correo[0] : null}
          value={formState.values.correo || ''}
        />
        <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                    //href='/pedidosConfirmar'
                    onClick={pushFunction}
                    fullWidth
                  >
                    Siguiente
                  </Button>
      </Grid></div>
      }
      </div>
      </Section>
        </div>
       );
};
    

export default PedidosUsuario;