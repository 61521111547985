import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  lastGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '40%',
    },
  },
  logoImage: {
    width: '10em',
    height: '3em',
  },
}));

const Customization = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        label=""
        title={
          <span>
            <Image
              className={classes.logoImage}
              src="/images/logos/logo.svg"
              alt="thefront"
              lazy={false}
              width={10}
            />
            <br />
            <Typography component="span" variant="inherit" color="primary">
              ¿Quienes Somos?
            </Typography>
            <Typography variant="h4" component="h4" style={{ color: "#ffffff" }}>
              Encuentra el producto natural que buscas en la mejor red de Productos Naturales.
            </Typography>
          </span>

        }
        subtitle=""
        align="center"

        ctaGroup={[

          <Button variant="contained" color="primary" size="large" href="/Album">
            Comienza Ahora, has tu pedido
          </Button>,
          <Button variant="contained" color="secondary" size="large" href="https://wa.me/3203500458">
            Quieres ser proveedor, solicitalo acá
          </Button>,
        ]}
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6}>
          <Grid container justif="center" alignItems="center">
            <Image
              src="/images/illustrations/ProductosNaturales/frutasC.gif"
              alt="TheFront Company"
              className={classes.image}
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            justif="center"
            alignItems="center"
            className={classes.lastGrid}
          >
            <Image
              src="/images/illustrations/dashboard-screenshot1.jpg"
              alt="TheFront Company"
              className={classes.image}
              data-aos="fade-up"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Customization.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Customization;
