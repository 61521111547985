import { React, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import Select from "react-select";
//import Select from "react-select";
import validate from 'validate.js';
import Swal from 'sweetalert2';
import requestApi from 'utils/request';
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CustomizedDialogs from 'views/CustomizedDialogs';
import CustomizedDialogsContrato from 'views/CustomizedDialogsContrato';
import Loading from 'views/Loading';
import Carrousel from 'views/Carrousel';
import Cities from './Cities';
import { Country, State, City } from "country-state-city";


const schema = {
  cantidad: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 120,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  formContainer: {
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
    //justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `1em auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  h1: {
    margin: "0 0 0 0",
    color: "#048568",
    fontWeight: "bold"
  },
  select: {
    margin: "0 0 1em 0",
    backgroundColor: "#000000"
  }
}));

function PedidosForm({product}) {
  const classes = useStyles();

  // const [cards, setFormState] = React.useState(
  //   [1]
  // );

  //const {values} = props.location.state;

  console.log("nombre producto form: " + product.nombre)
  const [sesion, setSesion] = useState('');
  const [sesionB, setSesionB] = useState(false);
  const [precioB, setPrecioB] = useState(false);

  //Si se usa usestate se debe usar useEfect.
  useEffect(() => {
    setSesion(sessionStorage.getItem('email'));
    console.log("sesion: " + sessionStorage.getItem('email'))
    if (sesion === null || sesion === undefined || sesion === "") {
      console.log("sesionB true")
      setSesionB(true)
    }
  });

  const [formState, setFormState] = useState({
    isValid: false,
    //values: { correo: sessionStorage.email },
    values: {},
    touched: {},
    errors: {},
  });

  const [pedido, setPedido] = useState([]);



  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

  }, [formState.values]);


  const handleClick= event => {
    event.persist();
    console.log("change click form pedidos")

    console.log("formState touched: " + formState.touched[event])
    console.log("handleChange values cantidad:---" + formState.values.cantidad)
   if (formState.values.cantidad != null ) {
 
      console.log("asignando precio")
      handlePrice();
    }

  };

  const handleChange = event => {
    event.persist();
    console.log("change form pedidos")

    if (formState.values.cantidad != null ) {
 
      console.log("asignando precio")
      handlePrice();
    }
    
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    formState.values.producto = product.nombre;
    //formState.values.cantidad = cantidad;
    console.log("formState touched: " + formState.touched[event])
    console.log("handleChange values cantidad:---" + formState.values.cantidad)


  };

  const hasError = field => {
    console.log("formState touched error: " + field)
    formState.touched[field] && formState.errors[field] ? true : false;
    //if(field==="cantidad"){
    // console.log("formState touched cantidad")
    //handlePrice();
    // }
  }

  const addCard = id => {
    let newId = id + 1;
    if (cards.includes(newId)) {
      addCard(newId * 2);
    } else {
      setFormState([...cards, newId]);
    }
  }

  const handleSubmit = event => {
    event.preventDefault();

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));

    if (formState.isValid) {
      requestApi("create", "pedidos", formState.values).then(function (response) {
        if (response.status == 200) {
      console.log("response status cerate pedidos: "+response.status)
        }
        console.log(response)
      })
    }
  };

  const [precioTotal, setPrecioTotal] = useState(0);
  const [moneda, setMoneda] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    console.log("precio: " + precioTotal)
    formState.values.precio = precioTotal;
    console.log("precio: formvalues " + formState.values.precio)
    console.log("precioB true")
   // formState.values.producto = product;
   formState.values.producto = product.nombre;
   console.log("values producto: " + formState.values.producto)

  });


  const handlePrice = async event => {

    console.log("handlePrice------------------------------")
    console.log("producto asignado: " + formState.values.producto)
    //if (formState.values.cantidad != null && formState.values.ciudad != null && formState.values.correo != null) {
    if (formState.values.cantidad != null) {
      setLoad(true)
      console.log("handlePrice")
      requestApi("consultarPrecio", "utils", formState.values).then(function (response) {
        if (response.status == 200) {
          console.log("status200 consulta precio");
          console.log("precioTotal " + response.data.result);
          console.log(response)
          setPrecioTotal(response.data.result.precio)
          setMoneda(response.data.result.moneda)
          setPrecioB(true);
          setLoad(false)
        }

      })

      console.log("precio: " + precioTotal)
    }
  };

  const removeCard = id => {
    console.log(id);
    console.log(cards);
    setFormState(cards.filter(item => item !== id))
  }
  /*this.props.router.push({
    pathname: '/pedidosConfirmar',
    state: {
      id: 7,
      color: 'green'
    }
  })*/

  let history = useHistory();
//Esto agrega el producto al carrito(tabla pedidos)
  const pushFunction = () => {
    console.log("push pedidosConfirmar form valid?: " + formState.isValid);
    if (formState.isValid) {
      // if (true) {
        formState.values.producto = product.nombre;
        console.log("values producto: " + formState.values.producto)
        formState.values.pais=selectedCountry;
        formState.values.estado=selectedState;
        formState.values.ciudad=selectedCity;
      //history.push('/pedidosConfirmar', { uid: "test props"})
      //history.push('/pedidosConfirmar', {producto:formState.values.producto})
       //history.push('/pedidosConfirmar', { values: formState.values })
       // El carrito se va armando con llave valor producto cantidad.(tabla pedidos_producto)
       //Si quiere cambiar cantidad debe volver agregar al carrito el producto.
       console.log("values cantidad: " + formState.values.cantidad)
       const pedido = formState.values.producto+" : "+formState.values.cantidad+","+precioTotal
       +","+formState.values.pais+","+formState.values.estado+","+formState.values.ciudad;

       //Asignacion de pedido a sessionStorage, creo que aca es mejor asignarlo como JSON #ACAVOY
      /* sessionStorage.setItem(formState.values.producto,formState.values.cantidad+","+precioTotal
       +","+formState.values.pais+","+formState.values.estado+","+formState.values.ciudad)*/

       let pedidoJ = {
        id_producto: product.id_producto,
        producto: formState.values.producto,
        cantidad: formState.values.cantidad,
        precioTotal: precioTotal,
        moneda: moneda,
        pais: formState.values.pais,
        estado:formState.values.estado,
        ciudad: formState.values.ciudad
      }

      console.log("producto pedido: "+pedidoJ.producto)
       //sessionStorage.setItem(pedidoJ.producto,pedidoJ)
       sessionStorage.setItem(pedidoJ.producto,JSON.stringify(pedidoJ))

       Swal.fire({
        title: 'Producto Agregado al carrito',
        icon: 'success',
        html: 'Su pedido ha sido agregado con exito: '+ pedido,
        showCancelButton: true,
        confirmButtonText: '<a href="/carrito"></a> Ir a Pagar',
        cancelButtonText:
          'Seguir comprando',
      }).then((result) => {
        if (result.isConfirmed) {
          // A dialog has been submitted
          //Lo agregue aca de nuevo. para que agrregue el producto al finalizar compra.
          sessionStorage.setItem(pedidoJ.producto,JSON.stringify(pedidoJ))
          history.push("/carrito");
        } else if (result.isDismissed) {
          // A dialog has been cancelled
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-buttons
          history.push("/Album");
        }
      })
      //.then(result => {history.push("/carrito");})
    }
  }

  const cleanForm = () => {
    console.log("cleanForm");

    formState.values = {};
    formState.values.precio = "";
    setPrecioTotal("");
    formState.values.movil = "";
    setSelectedCity("");
    setSelectedCountry("");
    setSelectedState("");


  };

  const [colombia, setColombia] = useState(true);

  const setColombiaValue = () => {
    console.log("setColombiaValue");

    setColombia(!colombia);


  };

  
  //Countries

  const [selectedCountry, setSelectedCountry] = useState("CO");
  const [selectedState, setSelectedState] = useState("Bogota");
  const [selectedCity, setSelectedCity] = useState("Bogota");
  useEffect(() => {
    console.log("selectedCountry: " + selectedCountry?.name);
    console.log("selectedCountry: ISO code" + selectedCountry?.isoCode);
    console.log(State?.getStatesOfCountry(selectedCountry?.isoCode));
    console.log("use effect country------------------------")
    //formState.values.pais = selectedCountry?.name;
    formState.values.pais = selectedCountry?.isoCode;
    if (selectedCountry != null) {
      console.log("formstate.country: " + formState.values.pais)
    }
  }, [selectedCountry]);

  useEffect(() => {
    console.log("selectedState: " + selectedState?.name);
    console.log("selectedState Iso Code: " + selectedState?.isoCode);
    console.log(State?.getStatesOfCountry(selectedState?.isoCode));
    console.log("use effect state------------------------")
    formState.values.estado = selectedState?.name;
    if (selectedCity != null) {
      console.log("formstate.state: " + formState.values.state)
    }
  }, [selectedState]);

  useEffect(() => {
    console.log("selectedCity: " + selectedCity?.name);
    console.log("selectedCity: iso Code" + selectedCity?.isoCode);
    console.log(State?.getStatesOfCountry(selectedCity?.isoCode));
    console.log("use effect City------------------------")
    formState.values.ciudad = selectedCity?.name;
    if (selectedCity != null) {
      console.log("formstate.city: " + formState.values.ciudad)
    }
  }, [selectedCity]);


  return (
    <div>
      <div className={classes.formContainer}>
        <Typography component="h4" variant="h2" color="primary" align='center'>
          Pedido:
        </Typography>
        <br></br>
        <br></br>
        <div>
          <form name="pedidos-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
         <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  //href='/pedidosConfirmar'
                  onClick={setColombiaValue}
                  fullWidth
                >
                  Fuera de Bogotá-Colombia ?
                </Button> 
                <br></br>
                <br></br>
            {!colombia ? <Grid item xs={12}>
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Pais *</h2></InputLabel>
                <Select
                  options={Country.getAllCountries()}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                  }}
                  required={true}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Estado *</h2></InputLabel>
                <Select
                  className={classes.select}
                  options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                  }}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.h1} >Ciudad *</h2></InputLabel>
                <Select
                  options={City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  )}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
              </Grid>: <></>}
            
              <Grid item xs={12}>
                <InputLabel size="normal" id="cantidad-label"><h2 className={classes.h1} >Cantidad *</h2></InputLabel>
                <br></br>
                <TextField
                  placeholder="Cantidad"
                  label="Cantidad, Número de Kilogramos *"
                  variant="filled"
                  size="medium"
                  name="cantidad"
                 // helperText={hasError('cantidad')}
                 fullWidth
                  error={hasError('cantidad')}
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="number"
                  value={formState.values.cantidad || ''}
                  defaultValue="0"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel size="normal" id="ciudad-label"><h2 className={classes.h1} >Precio *
                  <CustomizedDialogs></CustomizedDialogs></h2>
                  {load ? <Loading></Loading> : <></>}</InputLabel>
                <TextField
                  placeholder="Este precio se calcula automaticamente, espera por favor"
                  label="Precio total"
                  variant="filled"
                  size="normal"
                  name="precio"
                  fullWidth
                  onChange={handleChange}
                  onSubmit={handleClick}
                  onClick={handleClick}
                  type="text"
                  value={precioTotal + moneda || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <i>
                  <Typography variant="subtitle2">
                    Los Campos marcados con * son obligatorios.
                  </Typography>
                </i>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  //href='/pedidosConfirmar'
                  onClick={pushFunction}
                  fullWidth
                  disabled={!precioB}
                >
                  Agregar al Carrito
                </Button>
                <br></br>
                <br></br>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  //href='/pedidosConfirmar'
                  onClick={cleanForm}
                  fullWidth
                >
                  Limpiar
                </Button>
              </Grid>

            </Grid>
          </form>
        </div>
      </div>

      <br></br>
    </div>

  );
};

export default PedidosForm;