import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const palette = {
  "alternate": {
    //"main": "rgb(247, 249, 250)",
    "main": "rgb(171, 154, 189)",
    "dark": "#e8eaf6",
  },
  "cardShadow": "rgba(23, 70, 161, .11)",
  "common": {
    "black": "#000",
    "white": "#fff"
  },
  "type": "light",
  "primaryHome": {
    "main": "#1976d2",
    "light": "rgb(71, 145, 219)",
    "dark": "rgb(17, 82, 147)",
    "contrastText": "#fff"
  },
  "secondaryHome": {
    "light": "#ffb74d",
    "main": "#f9b934",
    "dark": "#f57c00",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "errorHome": {
    "light": "#e57373",
    "main": "#f44336",
    "dark": "#d32f2f",
    "contrastText": "#fff"
  },
  "warning": {
    "light": "#ffb74d",
    "main": "#ff9800",
    "dark": "#f57c00",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "info": {
    "light": "#64b5f6",
    "main": "#2196f3",
    "dark": "#1976d2",
    "contrastText": "#fff"
  },
  "success": {
    "light": "#81c784",
    "main": "#4caf50",
    "dark": "#388e3c",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "grey": {
    "50": "#fafafa",
    "100": "#f5f5f5",
    "200": "#eeeeee",
    "300": "#e0e0e0",
    "400": "#bdbdbd",
    "500": "#9e9e9e",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121",
    "A100": "#d5d5d5",
    "A200": "#aaaaaa",
    "A400": "#303030",
    "A700": "#616161"
  },
  "contrastThreshold": 3,
  "tonalOffset": 0.2,
  "textHome": {
    "primary": "rgba(0, 0, 0, 0.87)",
    "secondary": "rgba(0, 0, 0, 0.54)",
    "disabled": "rgba(0, 0, 0, 0.38)",
    "hint": "rgba(0, 0, 0, 0.38)"
  },
  "dividerHome": "rgba(0, 0, 0, 0.12)",
  "backgroundHome": {
    "paper": "#fff",
    "default": "#fff",
    "level2": "#f5f5f5",
    "level1": "#fff",
    "footer": "#1b1642",
  },
  "action": {
    "active": "rgba(0, 0, 0, 0.54)",
    "hover": "rgba(0, 0, 0, 0.04)",
    "hoverOpacity": 0.04,
    "selected": "rgba(0, 0, 0, 0.08)",
    "selectedOpacity": 0.08,
    "disabled": "rgba(0, 0, 0, 0.26)",
    "disabledBackground": "rgba(0, 0, 0, 0.12)",
    "disabledOpacity": 0.38,
    "focus": "rgba(0, 0, 0, 0.12)",
    "focusOpacity": 0.12,
    "activatedOpacity": 0.12
  },
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};

export default palette;