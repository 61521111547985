import { React, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Button } from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { useState, useEffect } from 'react';
import { forEach } from 'lodash';



const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  formContainer: {

  },
}));




function CarritoIcon() {
  const classes = useStyles();

  const [total, setTotal] = useState(1);
 var totalC = 0;

  const getTotalItems = () => {
    console.log("Total items sessionStorage: "+sessionStorage.length);
   
   console.log("Total items carrito: "+total);
    return total;
  }

  const [alertContent, setAlertContent] = useState('');
const [sesion, setSesion] = useState(0);

var keys = Object.keys(sessionStorage),
i = keys.length;

while ( i-- ) {
  console.log("key: "+keys[i])
  if((keys[i]==='bis_data')) {
    totalC = 1;
  }
}

//sessionStorage.clear();
  //El uso de useeffect es actualizar un componente cuando un valor usado en usestate cambia,
  //Esto es bastante util ya que permite que los componentes actualizen variables automaticamente
  // y se renderizen autoamticamente.
  useEffect(() => {
    console.log("Total items sessionStorage: "+sessionStorage.length);
    
   //para compensar una variable que crea en localstoragge el navegador al parecer, se resta 1
   setTotal(sessionStorage.length-totalC);

    console.log("sesion carrito: "+sesion)
    setAlertContent(sessionStorage.getItem('email'));
    if(alertContent !== null){
    setSesion(1)
    console.log("sesion carrito asignado: "+sesion)
    }else{
      setSesion(0)
    }
  });





  return (
   
      <div className={classes.formContainer}>
      <Button href="/Carrito">
        <Badge badgeContent={total-sesion} color="error">
          <AddShoppingCart />
        </Badge>
      </Button>
    </div>

  );
};

export default CarritoIcon;