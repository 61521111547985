import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import validate from 'validate.js';
import requestApi from '../../../../utils/request';
import Swal from 'sweetalert2';
import { Switch, Redirect, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Loading from 'views/Loading';
import Select from "react-select";
import { Country, State, City } from "country-state-city";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  floatingLabelFocusStyle:{
    color: "blue",
    fontWeight:"bold"
  }
}));

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
      tooLong: "maximo %{count} caracters",
    },
  },
  nombres: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 120,
      tooLong: "maximo %{count} caracters",
    },
  },
  apellidos: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 120,
      tooLong: "maximo %{count} caracters",
    },
  },
  contrasena: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 8,
      tooShort: "necesita minimo %{count} caracters",
    },
  },
  confirmcontrasena: {
    presence: { allowEmpty: false, message: '^Debe confirmar la contraseña' },
    equality: {
      attribute: "contrasena",
      message: "^Las contraseñas no coinciden. Por favor verifique"
    } 
  },
  celular: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 10,
      tooShort: "necesita minimo %{count} caracters",
    },
  },
  seudonimo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 6,
      tooShort: "necesita minimo %{count} caracters",
    },
  },
  direccion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 10,
      tooShort: "necesita minimo %{count} caracters",
    },
  },
 // ciudad: {
   //presence: { allowEmpty: false, message: 'es requerido' },    
 // },
};




const Form = () => {
  const classes = useStyles();
  const history = useHistory();
  const [load, setLoad] = useState(false);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  
  
  const handleSubmit = event => {
    event.preventDefault();
    
    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
    
    console.log("formState is valid? "+formState.isValid)

    if (formState.isValid) {
      setLoad(true)
      requestApi("create", "usuarios", formState.values).then(function (response) {
        if(response.status == 200 && response.data.result==='OK'){
          Swal.fire({
            title: 'Usuario creado con exito',
            text: 'Ingresa con tu nuevo usuario',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: '<a href="/signin-simple">Inicia Sesión</a>',
            cancelButtonText:
            'Cerrar',  
          }).then((result) => {
            setLoad(false)
            if (result.isConfirmed) {
              history.push("/signin-simple")
            }
        })
        } else {
          console.log("response result: "+response.data.result)
          setLoad(false)
          Swal.fire({
            title: 'Ocurrio un error',
            text: response.data.result,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'Entendido'
          })
        };
      })
    }

  };

  //Countries

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  useEffect(() => {
    console.log("selectedCountry: "+selectedCountry?.name);
    console.log(selectedCountry?.isoCode);
    console.log(State?.getStatesOfCountry(selectedCountry?.isoCode));
    console.log("use effect country------------------------")
    formState.values.pais = selectedCountry?.name;
    if(selectedCountry!=null){
      console.log("formstate.country: "+formState.values.country)
    }   
  }, [selectedCountry]);

  useEffect(() => {
    console.log("selectedState: "+selectedState?.name);
    console.log(selectedState?.isoCode);
    console.log(State?.getStatesOfCountry(selectedState?.isoCode));
    console.log("use effect state------------------------")
    formState.values.estado = selectedState?.name;
    if(selectedCity!=null){
      console.log("formstate.state: "+formState.values.state)
    }   
  }, [selectedState]);

  useEffect(() => {
    console.log("selectedCity: "+selectedCity?.name);
    console.log(selectedCity?.isoCode);
    console.log(State?.getStatesOfCountry(selectedCity?.isoCode));
    console.log("use effect City------------------------")
    formState.values.ciudad = selectedCity?.name;
    if(selectedCity!=null){
      console.log("formstate.city: "+formState.values.ciudad)
    }   
  }, [selectedCity]);


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
     

      <form name="contrasena-reset-form" method="post" onSubmit={handleSubmit}>
      {load?<Loading></Loading>:<></>}
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              placeholder="Nombres"
              label="Nombres *"
              variant="outlined"
              size="medium"
              name="nombres"
              fullWidth
              helperText={
                hasError('nombres') ? formState.errors.nombres[0] : null
              }
              error={hasError('nombres')}
              onChange={handleChange}
              type="text"
              value={formState.values.nombres || ''}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
              placeholder="Last name"
              label="Apellidos *"
              variant="outlined"
              size="medium"
              name="apellidos"
              fullWidth
              helperText={
                hasError('apellidos') ? formState.errors.apellidos[0] : null
              }
              error={hasError('apellidos')}
              onChange={handleChange}
              type="text"
              value={formState.values.apellidos || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
              placeholder="Correo electronico"
              label="Correo electronico *"
              variant="outlined"
              size="medium"
              name="correo"
              fullWidth
              helperText={hasError('correo') ? formState.errors.correo[0] : null}
              error={hasError('correo')}
              onChange={handleChange}
              type="email"
              value={formState.values.correo || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
              placeholder="contraseña"
              label="contraseña *"
              variant="outlined"
              size="medium"
              name="contrasena"
              fullWidth
              helperText={
                hasError('contrasena') ? formState.errors.contrasena[0] : null
              }
              error={hasError('contrasena')}
              onChange={handleChange}
              type="password"
              value={formState.values.contrasena || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
              placeholder="Confirmar contrasena"
              label="Confirmar contrasena *"
              variant="outlined"
              size="medium"
              name="confirmcontrasena"
              fullWidth
              helperText={
                hasError('confirmcontrasena') ? formState.errors.confirmcontrasena[0] : null
              }
              error={hasError('confirmcontrasena')}
              onChange={handleChange}
              type="password"
              value={formState.values.confirmcontrasena || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
              placeholder="Celular"
              label="Celular *"
              variant="outlined"
              size="medium"
              name="celular"
              fullWidth
              helperText={
                hasError('celular') ? formState.errors.celular[0] : null
              }
              error={hasError('celular')}
              onChange={handleChange}
              type="number"
              value={formState.values.celular || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
              placeholder="Seudonimo"
              label="Seudonimo *"
              variant="outlined"
              size="medium"
              name="seudonimo"
              fullWidth
              helperText={
                hasError('seudonimo') ? formState.errors.seudonimo[0] : null
              }
              error={hasError('seudonimo')}
              onChange={handleChange}
              type="text"
              value={formState.values.seudonimo || ''}
            />
          </Grid>
          <Grid item xs={12}>
                <InputLabel size="normal" id="producto-label"><h2 className={classes.floatingLabelFocusStyle} >Pais *</h2></InputLabel>
                <Select
                  options={Country.getAllCountries()}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                  }}
                  required={true}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.floatingLabelFocusStyle} >Estado *</h2></InputLabel>
                <Select
                  className={classes.select}
                  options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                  }}
                />
                <InputLabel size="normal" id="producto-label"><h2 className={classes.floatingLabelFocusStyle} >Ciudad *</h2></InputLabel>
                <Select
                  options={City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  )}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
              </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
            }}
              placeholder="Dirección"
              label="Dirección *"
              variant="outlined"
              size="medium"
              name="direccion"
              fullWidth
              helperText={
                hasError('direccion') ? formState.errors.direccion[0] : null
              }
              error={hasError('direccion')}
              onChange={handleChange}
              type="text"
              value={formState.values.direccion || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Los Campos marcados con * son obligatorios.
              </Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Registrarse
            </Button>
          </Grid>
        </Grid>
        <br></br>
      </form>
    </div>
  );
};

export default Form;
