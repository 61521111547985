import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    position: "sticky",
    top: "1rem",
    minWidth: "275"
  },

  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});


//#PENDIENTE:OK Boton para limpiar carrito, eliminar item, tal vez editar pedido. OK

export default function OrderSummaryItem() {
  const classes = useStyles();

   const [data, setData] = useState([]);
  //const [data, setData] = useState(null);
  const items = { ...localStorage };

  console.log("Shopping cart item");
  var values = [],
  keys = Object.keys(sessionStorage),
  i = keys.length;

 /* let pedido = {
    pedidos:[{
        producto: "",
        cantidad: "",
        precioTotal: 0,
        pais: "",
        estado: "",
        ciudad: ""
    }
    ]}*/

let objectPedidos = {
  producto: "",
  cantidad: "",
  precioTotal: 0,
  pais: "",
  estado: "",
  ciudad: "",
  total:0,
}
    let pedido = {
      pedidos:[
        objectPedidos
      ]}
  

 // let pedidosTest =[];
var precioTotalProd =parseFloat(0);

while ( i-- ) {
  console.log("key: "+keys[i])
  if(!(keys[i]==='bis_data') && !(keys[i]==='email')){
    objectPedidos = JSON.parse(sessionStorage.getItem(keys[i]))
    console.log(" value producto: "+ objectPedidos.producto)
    var thenum = objectPedidos.precioTotal;
    console.log(" precioTotal: "+ thenum)
    precioTotalProd = precioTotalProd + parseFloat(thenum);
    pedido.pedidos.push(objectPedidos)
    //pedido.pedidos.push(JSON.parse(objectPedidos))
  values.push(sessionStorage.getItem(keys[i]));
 // console.log(" value producto: "+ Object.values(pedido.pedidos[i]))
 //console.log(" value producto: "+ pedido.pedidos[i].producto)
 console.log(" value producto: "+ values[i])
  //values.push(JSON.parse( sessionStorage.getItem(keys[i])));
  
  //setData(keys[i] +"-"+sessionStorage.getItem(keys[i]))
 // setData.push( keys[i] +"-"+sessionStorage.getItem(keys[i]))
}
}

pedido.pedidos = Object.values(values);
let moneda = "N/A"
if(values.size>0){
 moneda = JSON.parse(values[0]).moneda;

//values[0].total = precioTotalProd;
//pedido.pedidos[0].total = "0";


values.map((value) =>
//if(value.key===1){
//console.log("ShopinCartItem: " +  value)
console.log("ShopinCartItem producto: " +  JSON.parse(value).producto)

);

}
console.log("pedido---: "+pedido.pedidos);
//console.log("pedido---: "+pedido.pedidos);
//console.log("pedido---: "+pedido.pedidos.producto);
//console.log("values data " + Object.values(pedido.pedidos[0]))
//setData(values);
  // Function to fetch data using Axios
  const fetchData = async () => {

    try {



    setData(pedido.pedidos);

      //console.log("values data " + Object.values(pedido.pedidos[0]))

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchData on component mount
  useEffect(() => {
    fetchData();
  }, []);
//#actual se quieren asignar varios items segun el mapeo para adicionar a carrito js.
let history = useHistory();

const handlePayment = () => {
  console.log("productos del carro:" + data)
  //aca se envia el nombre del producto
  history.push('/pagosPayU', { values: values })
};

const handlePaymentClean = () => {
  //#pendiente: OK aca se puede mejoprar que solo limpie el carro y no el email 
  //sessionStorage.clear();

  
values.map((value) =>
//if(value.key===1){
//console.log("ShopinCartItem: " +  value)
//console.log("ShopinCartItem producto: " +  JSON.parse(value).producto),
sessionStorage.removeItem(JSON.parse(value).producto)

);

history.push('/Album')
};

  return (
    <Card className={classes.root} elevation={15}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Carrito de compras
        </Typography>
        <Typography variant="div" component="h1">
          {" "}
          Resumen de orden
        </Typography>
        <Typography variant="subtitle2">
          <hr />
        </Typography>
        <Grid container>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <Typography variant="body1" component="div">
              Transporte
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Typography variant="h6" component="div">
              $0
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <Typography variant="body1" component="div">
              Total
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Typography variant="h6" component="div">
            {precioTotalProd + moneda}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Button size="large" color="secondary" onClick={handlePayment} >
          COMPRAR AHORA ({values.length})
        </Button>
        <Button size="large" color="secondary" onClick={handlePaymentClean} >
          LIMPIAR CARRITO ({values.length})
        </Button>
      </CardActions>
    </Card>
  );
}
