import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const schema = {
  producto: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 300,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor:"#AB9ABD"
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  h1: {
    margin: "0 0 0 0",
  },
  select: {
    margin: "0 0 1em 0",
  }
}));
//#PENDIENTE: Guardar pedido en base de datos su transaccion es exitosa.
//lapTransactionState=Exitosa.

const guardarPedido = async event => {
  console.log("guardarPedido------------------------------")



  //Asignar item de pedido

    formState.values.referenceCode = precioTotalProdS+moneda;
    formState.values.amount = precioTotalProdS;
    formState.values.currency = moneda;

  if (precioTotalProdS != null) {
  
    console.log("gcreate pedidos")
    requestApi("create", "pedidos", formState.values).then(function (response) {
      if (response.status == 200) {
        console.log("status200 getSignature");
        console.log("signature: ------- " + response.data.body);
        console.log(response)
        //setPrecioTotal(response.data.result.precio)
        formState.values.referenceCode = response.data.result;
        console.log("referenceCode: ------" + formState.values.referenceCode);
        setSignature(response.data.body)
      }

    })

  }
};


const RespuestaPayU = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  console.log("props: "+location.search)

 const query = new URLSearchParams(location.search);
 //const query = "";
  const merchantId = query.get('merchantId')
  console.log("response payu------------------------")
  console.log("merchantId: "+merchantId)


  const getQueryParams = (query = null) => {
    return (
      (query || window.location.search.replace("?", ""))
  
        // get array of KeyValue pairs
        .split("&") 
  
        // Decode values
        .map((pair) => {
          let [key, val] = pair.split("=");
  
          return [key, decodeURIComponent(val || "")];
        })
  
        // array to object
        .reduce((result, [key, val]) => {
          result[key] = val;
          return result;
        }, {})
    );
  };


  const getQueryMap = (query = null) => {
    return (
      (query || window.location.search.replace("?", ""))
  
        // get array of KeyValue pairs
        .split("&") 
  
        // Decode values
        .map((pair) => {
          let [key, val] = pair.split("=");
  
          return [key+": ", decodeURIComponent(val || "")];
        })
  
        // array to object
    );
  };

  const result = getQueryParams();
  const values = getQueryMap();

  console.log("result------- : "+Object.values(result));

  values.map((value) =>
    //thenum = value.precioTotal;
  //console.log(" precioTotal: "+ JSON.parse(value).precioTotal),
  console.log("value: "+value)
  //console.log("ShopinCartItem producto: " +  JSON.parse(value).producto)
  
  );

  return (
    <div>
    <h2>Resultado pagos PayU:</h2>
    <ul>
    {values.map(value => <p>{value}</p>)}
    </ul>      
  </div>

  );
};

export default RespuestaPayU;