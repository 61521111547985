import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: 15
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto",
    width: "1em"
  },
  cover: {
    [theme.breakpoints.down('md')]: {
      width:120,
    },
    [theme.breakpoints.up('md')]: {
      width:150,
    },
    [theme.breakpoints.up('lg')]: {
      width: 200,
    },
  }
}));

export default function ShoppingCartItem() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  //const [data, setData] = useState(null);
  const items = { ...localStorage };

  console.log("Shopping cart item");
  var values = [],
  keys = Object.keys(sessionStorage),
  i = keys.length;

 /* let pedido = {
    pedidos:[{
        producto: "",
        cantidad: "",
        precioTotal: 0,
        pais: "",
        estado: "",
        ciudad: ""
    }
    ]}*/

let objectPedidos = {
  producto: "",
  cantidad: "",
  precioTotal: 0,
  pais: "",
  estado: "",
  ciudad: "",
  total: 0,
}
    let pedido = {
      pedidos:[
        objectPedidos
      ]}
  

 // let pedidosTest =[];

while ( i-- ) {
  console.log("key: "+keys[i])
  if(!(keys[i]==='bis_data') && !(keys[i]==='email')){
    objectPedidos = JSON.parse(sessionStorage.getItem(keys[i]))
    console.log(" value producto: "+ objectPedidos.producto)
    pedido.pedidos.push(objectPedidos)
    //pedido.pedidos.push(JSON.parse(objectPedidos))
  values.push(sessionStorage.getItem(keys[i]));
 // console.log(" value producto: "+ Object.values(pedido.pedidos[i]))
 //console.log(" value producto: "+ pedido.pedidos[i].producto)
 console.log(" value producto: "+ values[i])
  //values.push(JSON.parse( sessionStorage.getItem(keys[i])));
  
  //setData(keys[i] +"-"+sessionStorage.getItem(keys[i]))
 // setData.push( keys[i] +"-"+sessionStorage.getItem(keys[i]))
}
}

pedido.pedidos = Object.values(values);
//pedido.pedidos = JSON.parse(JSON.stringify(values));
//#PENDIENTE: Se le podria agrregar a cada item la opcion de modificar la cantidad, con un
//icono de sumar o restar.

values.map((value) =>
//if(value.key===1){
//console.log("ShopinCartItem: " +  value)
console.log("ShopinCartItem producto: " +  JSON.parse(value).producto)

);


//console.log("pedido---: "+JSON.stringify(pedido.pedidos));
//console.log("pedido---: "+pedido.pedidos);
//console.log("pedido---: "+pedido.pedidos.producto);
//console.log("values data " + Object.values(pedido.pedidos[0]))
//setData(values);
  // Function to fetch data using Axios
  const fetchData = async () => {

    try {



    setData(pedido.pedidos);

      //console.log("values data " + Object.values(pedido.pedidos[0]))

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchData on component mount
  useEffect(() => {
    fetchData();
  }, []);
//#actual se quieren asignar varios items segun el mapeo para adicionar a carrito js.

//<Grid  container columns={{ xs: 4, md: 12 }} spacing={2}>
  return (
    <div >
    {values.map((product) => (
      
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={`/images/illustrations/${JSON.parse(product).producto}/${JSON.parse(product).producto}0.jpeg`}
        title="Imagen del producto"
        
      />
      <CardContent className={classes.content}>

        <Typography
         variant="div" component="h2"
        >
          Producto {JSON.parse(product).producto}
        </Typography>
        <Typography  className={classes.title}
          color="textSecondary"
          gutterBottom
          variant="subtitle2" >
        Pais {JSON.parse(product).pais}
        </Typography>
        <Typography variant="subtitle2">
          <hr />
        </Typography>
        <Grid conainer columns={{ xs: 4, md: 12 }} spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography variant="body1" component="div">
              Ciudad
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Typography variant="h6" component="div">
            {JSON.parse(product).ciudad}-{JSON.parse(product).estado}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography variant="body1" component="div">
            Cantidad 
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Typography variant="h6" component="div">
            {JSON.parse(product).cantidad}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <Typography
              variant="body1"
              component="div"
              style={{ fontWeight: "bold" }}
            >
              Precio Total 
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1}>
            <Typography variant="h6" component="div" color="secondary">
            {JSON.parse(product).precioTotal}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    ))}
      </div>
  );
}
