import axios from 'axios';
import Swal from 'sweetalert2';

const requestApi = (operation, table, item) => {
    const URL = process.env.REACT_APP_API_URL_LAMBDA;
    console.log("URL lambda: "+URL);
    let dataRequest = {
        "operation": operation,
        "tableName": table,
        "payload": {
            item
        },
    }
    return new Promise((resolve, reject) => {
        //test
      // axios.post('https://yit56ghyfwpql5q2nzrxy253vy0ojltn.lambda-url.us-east-1.on.aws/', dataRequest, {
        axios.post(URL, dataRequest, {
        //	https://d12kzzzj9mkgw0.cloudfront.net/
        //prod
       // axios.post('https://5xlj7wqtd7if3e5nh7gbfyvlkm0bqjxd.lambda-url.us-east-1.on.aws/', dataRequest, {
       // axios.post('https://d12kzzzj9mkgw0.cloudfront.net/', dataRequest, {

 
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                "Access-Control-Allow-Methods": "*",
                "Access-Control-Allow-Headers": "Content-Type",
                "Host":"d12kzzzj9mkgw0.cloudfront.net"
            }
        })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export default requestApi


// {
//     "data": "\"statusCode\": 200,\r\n\"headers\": {\"Content-Type\": \"application/json\"},\r\n\"body\":{\"operation\":\"getScan\",\"tableName\":\"usuarios\",\"payload\":{\"item\":{\"correo\":\"angelldamian1269@gmail.com\"}}},\r\n\"isBase64Encoded\": False,\r\n\"result\":{\n  \"items\": [],\n  \"count\": 0,\n  \"scannedCount\": 4,\n  \"sdkResponseMetadata\": {\n    \"metadata\": {\n      \"AWS_REQUEST_ID\": \"BHEA5K1O9985N76226BGCRGQB3VV4KQNSO5AEMVJF66Q9ASUAAJG\"\n    }\n  },\n  \"sdkHttpMetadata\": {\n    \"allHeaders\": {\n      \"Server\": [\n        \"Server\"\n      ],\n      \"Connection\": [\n        \"keep-alive\"\n      ],\n      \"x-amzn-RequestId\": [\n        \"BHEA5K1O9985N76226BGCRGQB3VV4KQNSO5AEMVJF66Q9ASUAAJG\"\n      ],\n      \"x-amz-crc32\": [\n        \"2937602604\"\n      ],\n      \"Content-Length\": [\n        \"39\"\n      ],\n      \"Date\": [\n        \"Mon, 10 Jul 2023 01:36:27 GMT\"\n      ],\n      \"Content-Type\": [\n        \"application/x-amz-json-1.0\"\n      ]\n    },\n    \"httpHeaders\": {\n      \"Connection\": \"keep-alive\",\n      \"Content-Length\": \"39\",\n      \"Content-Type\": \"application/x-amz-json-1.0\",\n      \"Date\": \"Mon, 10 Jul 2023 01:36:27 GMT\",\n      \"Server\": \"Server\",\n      \"x-amz-crc32\": \"2937602604\",\n      \"x-amzn-RequestId\": \"BHEA5K1O9985N76226BGCRGQB3VV4KQNSO5AEMVJF66Q9ASUAAJG\"\n    },\n    \"httpStatusCode\": 200\n  }\n}",
//     "status": 200,
//     "statusText": "OK",
//     "headers": {
//         "access-control-allow-origin": "*",
//         "access-control-expose-headers": "*",
//         "connection": "keep-alive",
//         "content-length": "1253",
//         "content-type": "application/json",
//         "date": "Mon, 10 Jul 2023 01:36:27 GMT",
//         "x-amzn-requestid": "f6d4b3c7-d726-4286-b769-9068ab10c49b",
//         "x-amzn-trace-id": "root=1-64ab609b-504cbe7f74bcbdb357ed06e0;sampled=0;lineage=7950ee78:0"
//     },
//     "config": {
//         "url": "https://yit56ghyfwpql5q2nzrxy253vy0ojltn.lambda-url.us-east-1.on.aws/",
//         "method": "post",
//         "data": "{\"operation\":\"getScan\",\"tableName\":\"usuarios\",\"payload\":{\"item\":{\"correo\":\"angelldamian1269@gmail.com\"}}}",
//         "headers": {
//             "Accept": "application/json, text/plain, */*",
//             "Content-Type": "application/json",
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Methods": "POST, GET, PUT",
//             "Access-Control-Allow-Headers": "Content-Type"
//         },
//         "transformRequest": [
//             null
//         ],
//         "transformResponse": [
//             null
//         ],
//         "timeout": 0,
//         "xsrfCookieName": "XSRF-TOKEN",
//         "xsrfHeaderName": "X-XSRF-TOKEN",
//         "maxContentLength": -1,
//         "maxBodyLength": -1,
//         "transitional": {
//             "silentJSONParsing": true,
//             "forcedJSONParsing": true,
//             "clarifyTimeoutError": false
//         }
//     },
//     "request": {}
// }