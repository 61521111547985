import React from 'react';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


const NutritionalInfo = () => {
    // Datos nutricionales del mango
    const nutritionalData = {
        calorias: '59 calorías',
        carbohidratos: '14,98 gramos',
        grasas: '0,38 gramos',
        proteinas: '0,82 gramos',
        vitaminaC: '36,4 miligramos',
        calcio: '11 miligramos',
        magnesio: '10 miligramos',
        fosforo: '14 miligramos',
        potasio: '168 miligramos'
    };

    return (
        <Grid container spacing={3}>
            {/* Columna de la imagen de la fruta */}
            <Grid item xs={12} md={6}>
                <img src="/images/illustrations/mangoTommy/mangoTommy2.jpeg" alt="Mango" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
            </Grid>

            {/* Columna de la tabla nutricional */}
            <Grid item xs={12} md={6}>
                <Typography variant="h6">Valor nutricional del mango por 100 gramos</Typography>
                <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#f0f8ff', color: '#1e90ff', borderBottom: '1px solid #ddd' }}>Nutriente</TableCell>
                                <TableCell style={{ backgroundColor: '#f0f8ff', color: '#1e90ff', borderBottom: '1px solid #ddd' }}>Valor por 100g</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Calorías</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.calorias}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Hidratos de carbono</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.carbohidratos}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Grasas totales</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.grasas}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Proteínas</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.proteinas}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Vitamina C</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.vitaminaC}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Calcio</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.calcio}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Magnesio</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.magnesio}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Fósforo</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.fosforo}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Potasio</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #ddd' }}>{nutritionalData.potasio}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default NutritionalInfo;
