import React from 'react';
//import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from 'views/Loading';


//#PENDIENTE: Colocar un logo de apto para exportacion que sea llamativo. En los productos
//que se quieran exportar: cacao, mango, limon, platano.

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#AB9ABD"
  },
  rootp: {
    backgroundColor: "#E7E0EF",
    marginBottom: "0em"
  },
  roota: {
    backgroundColor: "#AB9ABD",
    marginBottom: "0em"
  },

  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));
let onMouseOver = e => {

  this.setState({
    isEditable: true,
    text: e.target.value
  })
};


const Album = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  const URL = process.env.REACT_APP_API_URL_LAMBDA;
    console.log("URL lambda: "+URL);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  //const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const cards = [1, 2];


  // TODO remove, this demo shouldn't need to reset the theme.
  //const defaultTheme = createTheme();
  /*function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.selpii.net">
          www.selpii.net
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }*/
  const [productos, setProductos] = useState(
    []
  );

  const [productosO, setProductosO] = useState({

  });

  const [formState, setFormState] = useState({
    values: {},
  });

  const [load, setLoad] = useState(false);

  const [data, setData] = useState([]);
  let item;

  let dataRequest = {
    "operation": "getScan",
    "tableName": "productos",
    "payload": {
      "item": {
        "correo": ""
      }
    },
  }

  // Function to fetch data using Axios

  //#PENDIENTE: Usar requestAPi.js 
  //#PENDIENTE:La tabla cambio, ahora es productos, importante traer el id del producto y llevarlo hasta que se
  //registre el pedido en la tabla pedidos_productos: OK
  const fetchData = async () => {
    formState.values.correo = "";
    setLoad(true);
    item = formState.values.correo;
    try {


      const response = await axios.post(URL, dataRequest, {
        //	https://d12kzzzj9mkgw0.cloudfront.net/
        //prod
        // axios.post('https://5xlj7wqtd7if3e5nh7gbfyvlkm0bqjxd.lambda-url.us-east-1.on.aws/', dataRequest, {
        // axios.post('https://d12kzzzj9mkgw0.cloudfront.net/', dataRequest, {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Host": "d12kzzzj9mkgw0.cloudfront.net"
        }
      });

      setData(response.data.result);
      setLoad(false);
      console.log("response.data " + Object.values(response.data.result[0]))
      //console.log("response.data "+data.result)


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchData on component mount
  useEffect(() => {
    fetchData();
  }, []);

  let history = useHistory();

  const pushFunction = (product) => {


    console.log("producto seleccionado: " + product.nombre)
    //aca se envia el nombre del producto
    history.push('/pedidos', { values: product })
  }

  //console.log("leer JSONARRAY: "+Object.values(productos.userData[0]))


  return (
    <div >
      <div className={clsx(classes.rootp, className)}>
        {/* Hero unit (pendiente mejorar responsive)*/}

        <Container maxWidth="lg" marginBottom="1em">
          <Typography component="h2" variant="h2" color="primary" align='center'>
            SELECCIONA TU PRODUCTO NATURAL
          </Typography>
          <br></br>
          <Typography component="span" variant="h4" color="secundary" className='rootp'>
            En esta sección podras elegir tu producto, ver su descripción y realizar tu
            pedido.
          </Typography>
        </Container>


      </div>
      <div className={clsx(classes.root, className)} {...rest}>
        <Container sx={{ py: 8 }} maxWidth="md">
          {load ? <Loading></Loading> : <></>}
          {/* End hero unit */}
          <Grid container spacing={4}>
            {data.map((product) => (
              <Grid item key={product} xs={12} sm={6} md={4} marginRight={10}>
                <Typography noWrap variant="h4" component="span" style={{ color: "#3f51b5", fontWeight: "bold" }}>{product.nombre_comercial}</Typography>
                <Image
                  src={`/images/illustrations/${product.nombre}/${product.nombre}0.jpeg`}
                  onMouseOver={e => (e.currentTarget.src = `/images/illustrations/${product.nombre}/${product.nombre}1.jpeg`)}
                  width={300}
                  style={{ height: "25em" }}
                  onMouseOut={e => (e.currentTarget.src = `/images/illustrations/${product.nombre}/${product.nombre}0.jpeg`)}
                  margin="1em 2em 1em 1em"
                  maxWidth={300}
                >

                </Image>
                <Button variant="contained" color="primary" size="small" href={`/${product.nombre}`} >Ver Mas</Button>
                <Button sx={{ margin: 2 }} className={classes.button} variant="contained" color="secondary" size="small" onClick={() => pushFunction(product)}>Realizar Pedido</Button>
                <br></br>
                <br></br>
                <Box >
                  {/*<Typography variant="h4" component="span" style={{color:"#565DE5"}}>{product.nombre}</Typography>*/}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
        <div align="center">
          <Button size="normal" href="/pedidosUsuario" >Ver Tus Pedidos </Button>
        </div>
        {/* Footer */}
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
          <Typography variant="h6" align="center" gutterBottom>
            Selpii SAS. Colombia
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            component="p"
          >
            Calidad Garantizada
          </Typography>

        </Box>

        {/* End footer <Copyright />*/}


      </div>

    </div>




  );


};

Album.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Album;