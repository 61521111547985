import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import validate, { async } from 'validate.js';
import requestApi from '../../../../utils/request';
import { sessionData } from 'utils/sessionData';
import { Redirect, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import InstantMessage from '../../../InstantMessage/InstantMessage';
import { useState, useEffect } from 'react';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  contrasena: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 8,
    },
  },
};

const Form = () => {
  const classes = useStyles();
  const history = useHistory();




  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('Ingrese sus datos');

  const [alertSeverity, setAlertSeverity] = useState('info');
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    //#pendiente: OK aca se puede mejoprar que solo limpie el item email para que no borre el carrito de compras.
    sessionStorage.removeItem('email');
    console.log("session Storage LogOut: "+sessionStorage.getItem('email'));
  
    });

  const handleSubmit = async event => {
    event.preventDefault();

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));

   
    if (formState.isValid) {
      
      requestApi("validarContrasena", "usuarios", formState.values).then(async function (response) {
        console.log(response.data.result)
        console.log(response.data)
        console.log(response.status)
        console.log(response)
        if(response.data.result === "coinciden"){
          console.log("coinciden guardar sessionstorage")
          sessionStorage.setItem('email',formState.values.correo)
          setAlertContent("INICIO DE SESIÓN EXITOSO: "+ response.data.result);
          setAlert(true);
          setAlertSeverity("info");
          await delay (3000);
          sessionStorage.setItem('email',formState.values.correo)
          console.log("session Storage LogOut: "+sessionStorage.getItem('email'));
          history.push('/album');
        }else{
          setAlertContent("INICIO DE SESIÓN FALLIDO VERIFIQUE CORREO Y CONTRASEÑA: ");
          setAlert(false);
          setAlertSeverity("error");
          //alert(error);
        }
      })
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
    window.onload = function() {
      if(!window.location.hash) {
          window.location = window.location + '#loaded';
          window.location.reload();
      }
  }

  return (
    <div className={classes.root}>
     
      <form name="sign-in-form" method="post" onSubmit={handleSubmit}>
     <Alert severity='info'>Su sesión se ha cerrado correctamente</Alert> 
     <br></br>
    <Alert severity={alertSeverity}>{alertContent}</Alert>
      <br></br>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Correo electronico"
              label="Correo electronico *"
              variant="outlined"
              size="medium"
              name="correo"
              fullWidth
              helperText={hasError('correo') ? formState.errors.correo[0] : null}
              error={hasError('correo')}
              onChange={handleChange}
              type="email"
              value={formState.values.correo || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="contrasena"
              label="Contraseña *"
              variant="outlined"
              size="medium"
              name="contrasena"
              fullWidth
              helperText={
                hasError('contrasena') ? formState.errors.contrasena[0] : null
              }
              error={hasError('contrasena')}
              onChange={handleChange}
              type="password"
              value={formState.values.contrasena || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Los Campos marcados con * son obligatorios.
              </Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Iniciar sesión
            </Button>
            <br></br>
            <br></br>
            <Typography variant="subtitle2">
                Si no cuentas con una, entonces:
              </Typography>
         
            <br></br>
            <Button
              size="large"
              variant="contained"
              href="/signup-simple"
              color="primary"
              fullWidth
            >
              Crea tu cuenta
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
