import * as React from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import RalewayWoff2 from '../../../src/fonts/raleway-regular-webfont.woff2';
import PoetsenOneRegular from '../../../src/fonts/PoetsenOne-Regular.ttf';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);




const useStyles = makeStyles(theme => ({
  root: {},
  boxcolor:{
    color: "#4FEEEC",
    backgroundColor: "#AB9ABD",
    opacity: "0.8"
   
  },
  button:{
    margin: "1em 1em 1em 1em",
   
  },
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
    backgroundColor:"#AB9ABD"
  },
  paper:{
    display:"flex",
    justifyContent:"center",
  },
  poets:{
    fontFamily: "PoetsenOneRegular"
  }
}));



function CarrouselHome() {
  const themea = useTheme();
  const mobileDevice = useMediaQuery(themea.breakpoints.down('sm'));

  const images = [
    {
      label: "Productos del campo a directamente a tu supermercado",
      imgPath:
      "/images/illustrations/carrouselHome/carrouselHome1.jpeg",
    },
    {
      label: `Productos orgánicos certificados`,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome2.jpeg`,
    },
    {
      label: `La mejor calidad y sabor, has tu pedido en linea`,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome3.jpeg`,
    },
    {
      label: `El mejor precio y calidad del mercado, pagos contraentrega o en linea`,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome4.jpeg`,
    },
    {
      label: `Domicilios directamente a tu tienda, recibe solo si estas conforme `,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome5.jpeg`,
    },
  ];

  const imagesMovil = [
    {
      label: "Productos del campo a directamente a tu supermercado",
      imgPath:
      "/images/illustrations/carrouselHome/carrouselHome1movil.jpeg",
    },
    {
      label: `Productos orgánicos certificados`,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome2movil.jpeg`,
    },
    {
      label: `La mejor calidad y sabor, has tu pedido en linea`,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome3movil.jpeg`,
    },
    {
      label: `El mejor precio y calidad del mercado, pagos contraentrega o en linea`,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome4movil.jpeg`,
    },
    {
      label: `Domicilios directamente a tu tienda, recibe solo si estas conforme `,
      imgPath:
      `/images/illustrations/carrouselHome/carrouselHome5movil.jpeg`,
    },
  ];

  
  //const theme = useTheme();

  const poetsen = {
    fontFamily: 'PoetsenOneRegular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('PoetsenOneRegular'),
      local('PoetsenOneRegular-Regular'),
      url(${PoetsenOneRegular}) format('ttf')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'PoetsenOneRegular',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [poetsen],
        },
      },
    },
  });


  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      {!mobileDevice ? <Box width={"100%"} sx={{ mb:"0em" }} backgroundColor="#AB9ABD">
  
  <Paper className={classes.paper}
  sx={{
    bgcolor: '#AB9ABD',
  }}>
    <Typography align='center' variant="h4" component="span" 
    style={{fontSize:"3em",color:"#3f51b5", fontWeight:"bold",alignContent:"center",marginBottom:"2em",fontFamily:"PoetsenOneRegular" }}>{images[activeStep].label}</Typography>
 
 
 </Paper>
  <AutoPlaySwipeableViews
    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    index={activeStep}
    onChangeIndex={handleStepChange}
    enableMouseEvents
    backgroundColor="#AB9ABD"
   
  >
    {images.map((step, index) => (
      <div key={step.label}>
        {Math.abs(activeStep - index) <= 2 ? (
          <Box
          width={"100%"}
            component="img"
            sx={{
              height: 400,
              display: 'block',
              overflow: 'hidden',
            
            }}
            src={step.imgPath}
            alt={step.label}
          />
        ) : null}
      </div>
    ))}
  </AutoPlaySwipeableViews>
  <MobileStepper
    steps={maxSteps}
    color='#AB9ABD'
    className={classes.boxcolor}
    backgroundColor="#AB9ABD"
    position="static"
    activeStep={activeStep}
    nextButton={
      <Button
        size="small"
        onClick={handleNext}
        disabled={activeStep === maxSteps - 1}
      >
        Next
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </Button>
    }
    backButton={
      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
        Back
      </Button>
    }
  />
  <br></br>
  <Grid container justify="center">
  <Button align="center" variant="contained" color="primary" size="small" href="/quienes-somos" >Conocenos</Button>
  </Grid>
  
</Box> : <Box width={"100%"} sx={{ mb:"0em" }} backgroundColor="#AB9ABD">
  
  <Paper className={classes.paper}
  sx={{
    bgcolor: '#AB9ABD',
  }}>
    <Typography align='center' variant="h4" component="span" 
    style={{fontSize:"3em",color:"#3f51b5", fontWeight:"bold",alignContent:"center",marginBottom:"2em",fontFamily:"PoetsenOneRegular" }}>{imagesMovil[activeStep].label}</Typography>
 
 
 </Paper>
  <AutoPlaySwipeableViews
    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    index={activeStep}
    onChangeIndex={handleStepChange}
    enableMouseEvents
    backgroundColor="#AB9ABD"
   
  >
    {imagesMovil.map((step, index) => (
      <div key={step.label}>
        {Math.abs(activeStep - index) <= 2 ? (
          <Box
          width={"100%"}
            component="img"
            sx={{
              height: 400,
              display: 'block',
              overflow: 'hidden',
            
            }}
            src={step.imgPath}
            alt={step.label}
          />
        ) : null}
      </div>
    ))}
  </AutoPlaySwipeableViews>
  <MobileStepper
    steps={maxSteps}
    color='#AB9ABD'
    className={classes.boxcolor}
    backgroundColor="#AB9ABD"
    position="static"
    activeStep={activeStep}
    nextButton={
      <Button
        size="small"
        onClick={handleNext}
        disabled={activeStep === maxSteps - 1}
      >
        Next
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </Button>
    }
    backButton={
      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
        Back
      </Button>
    }
  />
  <br></br>
  <Grid container justify="center">
  <Button align="center" variant="contained" color="primary" size="small" href="/quienesSomos" >Conocenos</Button>
  </Grid>
  
</Box>}
    
    </ThemeProvider>
  );
}

export default CarrouselHome;