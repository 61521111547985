import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField, Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';
import validate from 'validate.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const schema = {
  producto: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 300,
    },
  },
  cantidad: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 50,
    },
  },
  direccion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 100,
    },
  },
  descripcion: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      maximum: 200,
    },
  },
  tipo: {
    presence: { allowEmpty: false, message: 'es requerido' },   
  },
  ciudad: {
    presence: { allowEmpty: false, message: 'es requerido' },   
  },
};

const Form = () => {
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formState.isValid) {
      window.location.replace('/');
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="producto-select-label">Producto</InputLabel>
              <Select
                labelId="producto-select-label"
                id="producto-select"
                label="Producto"
                onChange={handleChange}
                name='producto'
                helperText={hasError('producto') ? formState.errors.producto[0] : null}
                error={hasError('producto')}
                value={formState.values.producto || ''}
              >
                <MenuItem value={"Mango"}>Mango</MenuItem>
              </Select>
              <FormHelperText error>{hasError('producto') ? formState.errors.producto[0] : null}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Cantidad"
              label="Cantidad"
              variant="outlined"
              size="medium"
              name="cantidad"
              fullWidth
              helperText={hasError('cantidad') ? formState.errors.cantidad[0] : null}
              error={hasError('cantidad')}
              onChange={handleChange}
              type="number"
              value={formState.values.cantidad || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Precio total"
              label="Precio total"
              variant="outlined"
              size="medium"
              name="precio"
              fullWidth
              onChange={handleChange}
              type="string"
              value={formState.values.precio || ''}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Realizar pedido
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
