import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';

import {  useHistory } from 'react-router-dom';
import { Image } from 'components/atoms';
import { useState, useEffect } from 'react';
import validate from 'validate.js';


var qrbancolombiasrc = '/images/illustrations/qrbancolombia.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  logoImage: {
    width: '20em',
    height: '5em',
  },
  qrbancolombia: {
   // maxWidth: '20em',
    width: '40em',
    height: '50em',
    marginLeft: '1em',
    marginRight: '1em',
    marginTop:'1em',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5em',  
    [theme.breakpoints.down('sm')]: {
      width: '20em',
      height:'25em',
      marginLeft: '1em',
      marginRight: '1em',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2em',  
      maxWidth: 400,
      src:'/images/illustrations/CodigoQR.jpg',
      qrbancolombiasrc: '/images/illustrations/CodigoQR.jpg',
    }, 
  },
  qr: {
    width: '40em',
    height: '30em',
    marginLeft: '1em',
    marginRight: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5em',  
    [theme.breakpoints.down('sm')]: {
      width: '40em',
      height: '30em',
      marginLeft: '1em',
      marginRight: '1em',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5em',  
      maxWidth: 400,
      imageqr:'',
    }, 
  },
  buttonPadding: {    
    padding: '30px',   
  },
}));

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  contrasena: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 8,
    },
  },
};

function Form(product) {
  const classes = useStyles();

  // const [cards, setFormState] = React.useState(
  //   [1]
  // );

  //const {values} = props.location.state;

  console.log("value from: " + Object.values(product))

 console.log("pedido pagos: "+product);

  const pedido = Object.values(product);



  const history = useHistory();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(false);
  const [alertContent, setAlertContent] = useState('Ingrese sus datos');

  const [alertSeverity, setAlertSeverity] = useState('info');


  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  





 

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
        <Grid container spacing={4} m={2} pt={3}>
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
            m={2} pt={3}
          >
            
            <Image
              className={classes.qrbancolombia}
              src={qrbancolombiasrc}
              alt="thefront"
              lazy={false}
            />
        
          </Grid>
          <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}>
            
            <Image
              className={classes.qr}
              src="/images/illustrations/cuentaAhorrosSelpii.png"
              alt="thefront"
              lazy={false}
       
            />
         
          </Grid>
          <Grid item xs={12}>
            <i>
            <Typography component="h4" variant="h4" color="primary" align='center'>
                Elige una forma de pago.
              </Typography>
              <Typography component="h4" variant="h4" color="primary" align='center'>
              enviar comprobante a Whatsapp o al correo alejo.limp@gmail.com
              </Typography>
             

             
            </i>
          </Grid>
          <br></br>
          <br></br>
        </Grid>
        <Button
            size="large"
            variant="contained"
            href="https://wa.me/3203500458"
            color="primary"
            fullWidth
          >
            Deseo hacer la compra con un asesor.
          </Button>

    </div>
  );
};

export default Form;
