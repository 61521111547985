import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import validate, { async } from 'validate.js';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useState, useEffect } from 'react';
import Loading from 'views/Loading';
import { Form } from 'views/PagosPayU/components';
import Box from '@mui/material/Box';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
}));

const schema = {
  correo: {
    presence: { allowEmpty: false, message: 'es requerido' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  contrasena: {
    presence: { allowEmpty: false, message: 'es requerido' },
    length: {
      minimum: 8,
    },
  },
};

const PagosPayU = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;

  const { values } = props.location.state;
  console.log("nombre producto----: " + (values))


  const history = useHistory();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(false);
  const [alertContent, setAlertContent] = useState('Ingrese sus datos');

  const [alertSeverity, setAlertSeverity] = useState('info');


  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleSubmit = async event => {
    event.preventDefault();

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));


    if (formState.isValid) {
      setLoad(true);

    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
//#PENDIENTE Agregar forma de pago contraentrega y opracticamente copiar el componente de pagosQR
  return (
    <div className={classes.root}>
      <Typography component="h2" variant="h2" color="primary" align='center'>
        ELIGE TU FORMA DE PAGO
      </Typography>
      <Form product={values} ></Form>

      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Selpii SAS. Colombia
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Calidad Garantizada
        </Typography>
       
      </Box>

    </div>
  );
};

export default PagosPayU;
